import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import API                      from 'API';
import moment                   from 'moment';
import _                        from 'lodash';

import { deployDialog, closeDialog } from 'Actions/Dialog/Dialog';
import {setPersistence}              from 'Actions/StatePersistence/StatePersistence';

import DateRangeDialog from './../DateRangeDialog';
import ReportCell      from './../ReportCell';

import LoadingCircle                         from 'Components/Common/LoadingCircle/LoadingCircle';
import AllIcon                               from 'Components/Common/Icons/AllIcon';
import DataTable                             from 'Components/Common/DataTables/CiDataTable';
import CustomerReturnsEmailPrevDocsDialog    from 'Components/CustomerReturns/CustomerReturnsEmailPrevDocsDialog/CustomerReturnsEmailPrevDocsDialog';
import CustomerReturnsDownloadPrevDocsDialog from 'Components/CustomerReturns/CustomerReturnsDownloadPrevDocsDialog/CustomerReturnsDownloadPrevDocsDialog';
import OrderDownloadPrevDocsDialog           from 'Components/Sales/Order/OrderDownloadPrevDocsDialog/OrderDownloadPrevDocsDialog';
import OrderEmailPrevDocsDialog              from 'Components/Sales/Order/OrderEmailPrevDocsDialog/OrderEmailPrevDocsDialog';
import DownloadCSV                           from 'Components/Common/Buttons/DownloadCSV';
import PaddedPaper                           from 'Components/Common/Paper/PaddedPaper';
import PieChart                              from 'Components/Common/Charts/PieChart';
import LineChart                             from 'Components/Common/Charts/LineChart';
import BarChart                              from 'Components/Common/Charts/BarChart';

import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons                      from 'Helpers/IconHelper';
import { SystemColors }           from 'Helpers/ColourHelper';

import { Card, CardContent, CardHeader, Typography, Grid, Divider, Tooltip, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

import { getInitialState, hasPageState, savePageState, clearPageState } from 'Functions/StatePersistenceFunctions';

const styles = theme => ({
    card: {
        color: '#000',
        backgroundColor: '#FFF',
        padding: '0'
    },
    row: {
        color: '#000',
        backgroundColor: '#FFF',
        '&:hover': {
            backgroundColor: '#FCFCFC',
            cursor: 'pointer'
        },
    },
    header: {
        background:'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', 
        color: '#FFF'
    },
    legendWrapper: {
        padding: '0 1rem'
    },
    legendTitle: {
        marginBottom: '0.8rem'
    },
    legendItem: {
        display: 'grid',
        gridTemplateColumns: '30px 60px auto',
        marginBottom: '0.4rem'
    },
    iconButton: {
        "&:hover": {
            backgroundColor: 'transparent'
        }
    }

});

const colors = SystemColors();

const initialState = {
    filter:      {
        to:         moment().format('YYYY-MM-DD'),
        from:       moment().format('YYYY-MM-DD'),
        type:       'day'
    },
    overview:    {},
    orders:      {},
    isLoading:   true,
    showResults: false,
    formErrors:  null,
    reduce:      false
}

class SalesReportDetailed extends PureComponent {
    constructor(props) {
        super(props);
        this.clearPageState  = clearPageState.bind(this);
        this.getInitialState = getInitialState.bind(this);
        this.hasPageState    = hasPageState.bind(this);
        this.savePageState   = savePageState.bind(this);
        switch (true){
            case     props.cid > 0:    this.persistenceId = `SalesReport:detailed:cid:${props.cid}`;    break;
            case     props.partId > 0: this.persistenceId = `SalesReport:detailed:pid:${props.partId}`; break;
            default:                   this.persistenceId = `SalesReport:detailed`;                     break;
        }
        this.state           = this.getInitialState(initialState)
    }

    handleSetFilterParams = (filter) => { this.setState({filter, showResults: true}, this.getData)} 

    getOverview() {
        const params = {params: {...this.state.filter, cid:this.props.cid, partId:this.props.partId}}
        API.get( '/reports/salesReport/detailed/overview' , params)
        .then( res =>{
            if (res.data.errors){
                this.setState({formErrors:formatValidationErrors(res.data.errors)})
            } else {
                this.setState({
                    overview:   (res.data.in.qty || res.data.out.qty) && res.data
                },()=>{this.savePageState()});
            }
        })
    }

    getOrders = () => {
        const params = {params: {...this.state.filter, cid:this.props.cid, partId:this.props.partId}}
        API.get( '/reports/salesReport/detailed/orders' ,   params)
        .then( orders => {
            if (Object.values(orders.data).length < 200){
                this.setState({
                    orders:     orders.data
                }, () => {
                    this.setState({isLoading:  false},()=>{this.savePageState()});
                })
            } else {
                this.setState({isLoading: false, reduce: true},()=>{this.savePageState()});
            };
        } )
    }

    getData = () => {
        this.setState({isLoading: true, reduce: false, orders: {}, overview: {}}, ()=> {
            this.getOverview();            
            this.getOrders();
        })
    }

    handleDownloadOpen = (id,cn) => {
        if (cn){
            this.props.deployDialog(<CustomerReturnsDownloadPrevDocsDialog cnId={id} />, 'Download Previous Documents', 'md');
        } else {
            this.props.deployDialog(<OrderDownloadPrevDocsDialog orderId={id} />, 'Download Previous Documents', 'md');
        }
    }
    

    handleEmailOpen = (id,cn) => {
        if (cn){
            this.props.deployDialog(<CustomerReturnsEmailPrevDocsDialog cnId={id} />,  'Email Previous Documents', 'md');
        } else {
            this.props.deployDialog(<OrderEmailPrevDocsDialog orderId={id} />, 'Email Previous Documents', 'md');
        }
    } 
    
    getCsvData = () => {
        let data = []; 
        let {filter, orders} = this.state;
        _.each(_.orderBy(Object.keys(orders),i=>{return i},'asc'), date => {
            if (orders[date]){
                _.each(orders[date] , o => {
                    data.push({
                        date:           o.d,
                        invoiceDate:    o.id,
                        orderRef:       o.r,   
                        customer:       o.c.n,
                        net:            o.cn ? 0 - o.s : o.s,
                        vat:            o.cn ? 0 - o.v : o.v,
                        gross:          o.cn ? 0 - o.t : o.t,
                    })
                })
            } 
        });
        return data;
    }

    getOrdinal = day => {
        const number = parseInt(day);
        switch (number) {
            case 1:
            case 21:
                return number + 'st';
            case 2:
            case 22:
                return number + 'nd';
            case 3:
            case 23:
                return number + 'rd';
            default:
                return number + 'th';
        }
    }

    getLineData = () => {
        const orders =  this.state.orders;
        let type =  this.state.filter.type;
        switch(type){
            case 'all':
            case 'custom':
            case 'month':
            case 'week':
                return _.map(_.orderBy(Object.keys(orders),i=>{return i},'asc'), date => {
                    let j = this.state.orders[date];
                    return _.assign({
                        label:  this.state.filter.type === 'week' ? moment(date).format('ddd') : moment(date).format('DD/MM/YYYY'),
                        date:   moment(date).format('YYYY-MM-DD'),
                        value: {
                            Invoiced: parseFloat( _.sum( _.map( _.filter( j, i => { return !i.cn } ), i=>parseFloat(i.s) ) ) ).toFixed(2),
                            Credited: parseFloat( _.sum( _.map( _.filter( j, i => { return i.cn  } ), i=>parseFloat(i.s) ) ) ).toFixed(2)
                        },
                        color: {
                            Invoiced: colors.blue,
                            Credited: colors.red
                        }
                    })
                })
            case 'fyear':
                let ret = [];
                _.map(_.orderBy(Object.keys(orders),i=>{return i},'asc'), date => {
                    let j = this.state.orders[date];
                    let week = moment(date).format('D')
                    if (!ret[week]) 
                        ret[week] = {
                            //label: `${moment(date).startOf('week').format('DD/MM/YYYY')} - ${moment(date).endOf('week').format('DD/MM/YYYY')}`,
                            label:  moment(date).format('MMM YYYY'),
                            date:   moment(date).format('YYYY-MM-DD'),
                            value: {
                                Invoiced: parseFloat( _.sum( _.map( _.filter( j, i => { return !i.cn } ), i=>parseFloat(i.s) ) ) ),
                                Credited: parseFloat( _.sum( _.map( _.filter( j, i => { return i.cn  } ), i=>parseFloat(i.s) ) ) )
                            },
                            color: {
                                Invoiced: colors.blue,
                                Credited: colors.red
                            }
                        };

                    if (ret[week]) {
                        ret[week].value.Invoiced += parseFloat( _.sum( _.map( _.filter( j, i => { return !i.cn } ), i=>parseFloat(i.s) ) ) );
                        ret[week].value.Credited += parseFloat( _.sum( _.map( _.filter( j, i => { return i.cn  } ), i=>parseFloat(i.s) ) ) );
                    }
                })
                return _.orderBy(_.filter(ret, i => i), 'date', 'asc');
            default: return [];
        }
    }

    getLineChartLabel = (value, index, values ) => {
        let   type = this.state.filter.type, format = '';

        if (type === 'custom'){
            const start = moment(_.first(_.orderBy(Object.keys(this.state.orders),i=>{return i},'asc')))
            const end   = moment(_.last(_.orderBy(Object.keys(this.state.orders),i=>{return i},'asc')))
            const diff = end.diff(start,'days');

            type = diff < 13 ? 'iday' : ( diff < 31 ? 'month' : 'fyear' );
        }

        switch(type){
            case 'iday':  format = 'ddd MMM';       break;
            case 'month': format = '[week] W YYYY'; break;
            case 'fyear': format = 'MMM YYYY';      break;
            default:                                return values[value].label;
        }

        if (!value) return moment(values[value].date).format(format);
        const to =   values[value].date, from =  values[(value-1)]?.date;
        return (moment(to).format(format) === moment(from).format(format)) ? null : moment(to).format(format);      
    }

    render() {
        const { isLoading, filter, overview, orders, showResults, formErrors, reduce }  = this.state;
        const { classes }                                                               =  this.props;
        return (
            <Grid container xs={12} spacing={3}>
                <Grid item xs={12} lg={4}>
                    <PaddedPaper>
                        <DateRangeDialog 
                            callback={this.handleSetFilterParams} 
                            error={formErrors} 
                            formData={this.state.filter}
                        />
                    </PaddedPaper>
                </Grid>
                {showResults &&
                    <>
                        {isLoading ? <Grid item xs={12} lg={8}><LoadingCircle/></Grid> : 
                            <>
                                <Grid item xs={12} lg={3}>
                                    {!_.isEmpty(overview) &&
                                        <PaddedPaper>
                                            <PieChart 
                                                data={[
                                                    {label: 'Invoiced',    value: parseFloat(overview.in.net ).toFixed(2), color: colors.blue},
                                                    {label: 'Credited',    value: parseFloat(overview.out.net).toFixed(2), color: colors.red},
                                                ]} 
                                                style={{ height: 245 }}
                                                tLabel={(e)=>{return `£${parseFloat(e.raw).toFixed(2)}`}}
                                            />
                                        </PaddedPaper>
                                    }
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {!_.isEmpty(orders) &&
                                        <PaddedPaper>
                                            {filter.type === 'day' ?
                                                <BarChart 
                                                    data={[
                                                        {label: 'Invoiced',    value: parseFloat(overview.in.qty ).toFixed(2), color: colors.blue},
                                                        {label: 'Credited',    value: parseFloat(overview.out.qty).toFixed(2), color: colors.red},
                                                    ]} 
                                                    sidebar
                                                    tick={1}
                                                /> : 
                                                <LineChart 
                                                    data={this.getLineData()} 
                                                    style={{ height: 245 }}
                                                    xLabel={this.getLineChartLabel}
                                                    yLabel={(value) => {return '£'+value;}}
                                                    tLabel={(e)=>{return `£${parseFloat(e.raw).toFixed(2)}`}}
                                                />
                                        }
                                        </PaddedPaper>
                                }
                                </Grid>
                            </>
                        }
                        {!_.isEmpty(overview) && 
                            <Grid item xs={12} lg={12}>
                                <Card>
                                    <CardHeader
                                        avatar={<AllIcon icon={ icons['sales'] } size='medium' style={{color: '#fff'}} />}
                                        title={`Sales Report For ${
                                            filter.to === filter.from ?
                                                moment(filter.from).format('dddd Do of MMMM') :
                                                `${moment(filter.from).format('DD/MM/YYYY')} To ${moment(filter.to).format('DD/MM/YYYY')}`
                                            }`}
                                        titleTypographyProps={{variant:'h6'}}
                                        className={classes.header}
                                    />
                                    <CardContent style={{padding:5}}>
                                        <Table size="small" aria-label="a dense table" style={{color:'black'}}>
                                            <TableHead style={{backgroundColor:'#f5f5f5'}}>
                                                <TableRow>
                                                    <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}></TableCell>
                                                    <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Invoices / Credits</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Qty</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'10%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Stock</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Net</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Vat</b></Typography></TableCell>
                                                    <TableCell align='center'   style={{width:'20%', borderBottom: 'none', color:'black'}}><Typography style={{fontWeight: '600'}}><b>Gross</b></Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow class={classes.row} sx={{ '&:last-child td, &:last-child th': { border: 0 }}} >
                                                    <TableCell align='left' style={{padding:5, color:'black', paddingLeft: '0.6rem',  borderBottom: 0}}>
                                                        <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                            <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} primaryColor='#33dd33' />
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                Invoiced
                                                            </Typography>
                                                        </Box>
                                                        <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                            <AllIcon icon={'arrow-down-arrow-up'} size='medium' duo style={{display: 'inline-block', paddingRight: '0.5rem'}} swapOpacity secondaryColor={colors.red} />
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                Credited
                                                            </Typography>
                                                        </Box>
                                                        <Box style={{display: 'flex', alignItems: 'center', height: '32px'}}>
                                                            <AllIcon icon={'equals'}  size='medium' style={{display: 'inline-block', paddingRight: '0.5rem', color: '#2d2d2d'}} />
                                                            <Typography variant="h6" style={{fontSize: '14px'}}>
                                                                Total
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <ReportCell data={overview} field={'qty'}   style={{borderBottom: 0}} hidePound/>
                                                    <TableCell align='center' style={{borderBottom: 0, color:'black'}}>
                                                        {parseFloat(overview.in.parts) > 0 ?
                                                            <Typography variant="h6" style={{color:'black',fontSize: '20px'}}>
                                                                {parseFloat(overview.in.parts).toFixed(3)}
                                                            </Typography> :
                                                            <Typography variant="h6" style={{color:'black'}}>-</Typography>
                                                        }
                                                        {parseFloat(overview.out.parts) > 0 ?
                                                            <Typography variant="h6" style={{color:colors.red,fontSize: '20px'}}>
                                                                {parseFloat(overview.out.parts).toFixed(3)}
                                                            </Typography> :
                                                            <Typography variant="h6" style={{color:'black'}}>-</Typography>
                                                        }
                                                        {parseFloat( parseFloat(overview.in.parts) - parseFloat(overview.out.parts) ) !== 0 ? 
                                                           <Typography variant="h6" style={{color: parseFloat( parseFloat(overview.in.parts) - parseFloat(overview.out.parts) ) < 0 ? colors.red : colors.black ,fontSize: '20px'}}>
                                                                {parseFloat( parseFloat(overview.in.parts) - parseFloat(overview.out.parts) ).toFixed(3)}
                                                            </Typography> :
                                                        <Typography variant="h6" style={{color:'black'}}>-</Typography>
                                                     }

                                                    </TableCell>
                                                    <ReportCell data={overview} field='partsStock' hidePound forceFixed={3} inverse style={{borderBottom: 0}} />
                                                    <ReportCell data={overview} field={'net'}   style={{borderBottom: 0}} />
                                                    <ReportCell data={overview} field={'vat'}   style={{borderBottom: 0}} />
                                                    <ReportCell data={overview} field={'total'} style={{borderBottom: 0}} />
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </CardContent>
                                </Card>
                            </Grid>
                        }
                        {isLoading ? <Grid item xs={12} lg={12}><LoadingCircle/></Grid> : 
                        <>
                            {!_.isEmpty(orders) ? 
                                <>
                                    <Grid item xs={12} lg={12} style={{padding:'1em 0px'}}>
                                        <Divider/>
                                    </Grid>
                                    <Grid item xs={12} lg={12}>
                                        <DownloadCSV data={this.getCsvData()} color='primary' variant='contained' filename={`${filter.from}_to_${filter.to}_sales_report`} style={{marginBottom: '1em'}}>download Report</DownloadCSV>
                                        {orders &&
                                            <Grid container xs={12} lg={12} spacing={3}>
                                                {_.map(_.orderBy(Object.keys(orders),i=>{return i},'asc'), date => {
                                                    if (orders[date]){
                                                        return (
                                                            <Grid item xs={12} lg={12}>
                                                                <Card>
                                                                    <CardHeader
                                                                        title={moment(date).format(`dddd [${this.getOrdinal(moment(date).format('D'))}] MMMM YYYY`)}
                                                                        titleTypographyProps={{variant:'h6', style:{fontWeight:'bold'}}}
                                                                    />
                                                                    <CardContent style={{padding:5}}>
                                                                    <DataTable  
                                                                        config={{
                                                                            key: 'i',
                                                                            pagination: false,
                                                                            alternatingRowColours: true,
                                                                            responsiveimportant: true,
                                                                            isLoading: isLoading,
                                                                            showTotals: true,
                                                                            totalStyle: {fontWeight: 'bold'},
                                                                            /*totalColumns: [
                                                                                {heading:'Payment Method',  label: data => <Typography style={{textAlign:'right', fontSize: '18px', fontWeight: 'bold'}} variant='h6'>Total:</Typography>},
                                                                                {heading:'Gross',           label: data => <Typography style={{textAlign:'right', fontSize: '18px', fontWeight: 'bold'}} variant='h6'>{data < 0 ? '-£' : '£'}{parseInt(data).toFixed(2)}</Typography>, data: rowData => { return parseFloat( rowData.cn ? rowData.t - (rowData.t * 2) : rowData.t ) } },
                                                                                {heading:'Vat',             label: data => <Typography style={{textAlign:'right', fontSize: '18px', fontWeight: 'bold'}} variant='h6'>{data < 0 ? '-£' : '£'}{parseInt(data).toFixed(2)}</Typography>, data: rowData => { return parseFloat( rowData.cn ? rowData.v - (rowData.v * 2) : rowData.v ) } },
                                                                                {heading:'Net',             label: data => <Typography style={{textAlign:'right', fontSize: '18px', fontWeight: 'bold'}} variant='h6'>{data < 0 ? '-£' : '£'}{parseInt(data).toFixed(2)}</Typography>, data: rowData => { return parseFloat( rowData.cn ? rowData.s - (rowData.s * 2) : rowData.s ) } },
                                                                            ]*/
                                                                        }}
                                                                        columns={[
                                                                            {
                                                                                heading: 'Date',
                                                                                field: rowData => <div style={{color: rowData.cn && colors.red}}>{moment(rowData.d).format("DD/MM/YYYY")}</div>,
                                                                                important: true,
                                                                                sizeToContent: true,
                                                                                dataRef: 'd',
                                                                            },
                                                                            {
                                                                                actionsCustomHeader:    true,
                                                                                important:              true,
                                                                                alignment:              'left',
                                                                                heading:                'Reference',
                                                                                actions: rowData => {
                                                                                    if (rowData.cn) return [
                                                                                        { label: rowData.r,     link: `/returns/creditnote/${rowData.i}`,     type:'plainLink', color: colors.red},
                                                                                        { label: rowData.o.r,   link: `/sales/order/view/${rowData.o.i}`,     type:'plainLink'},
                                                                                    ];
                                                                                    return [
                                                                                        { label: rowData.r,     link: `/sales/order/view/${rowData.i}`,       type:'plainLink'},
                                                                                        { label: rowData.q.r,   link: `/sales/quotation/view/${rowData.q.i}`, type:'plainLink', class: "textSecondary"},
                                                                                        { label: rowData.crn.r, link: `/returns/creditnote/${rowData.crn.i}`, type:'plainLink', color: colors.red}
                                                                                    ]
                                                                                },
                                                                                dataRef: 'r',
                                                                            },
                                                                            {
                                                                                heading: 'Discount',
                                                                                field: rowData => rowData.di,
                                                                                important: true,
                                                                                fieldFormat: 'boolean',
                                                                                tooltip: rowData => rowData.di,
                                                                                sizeToContent: true,
                                                                                alignment: 'center',
                                                                                dataRef: 'di',
                                                                            },
                                                                            {
                                                                                heading: 'Invoices',
                                                                                field: rowData => <div style={{maxWidth:100, overflow:'hidden',textOverflow: "ellipsis"}}>
                                                                                    {rowData.in.n}
                                                                                    <br/>
                                                                                    Sage: {rowData.in.sn}
                                                                                </div>,
                                                                            },
                                                                            {
                                                                                heading: 'Customer',
                                                                                field: 'c.n',
                                                                                truncate: true,
                                                                                style: rowData =>({color: rowData.cn && colors.red}),
                                                                                important: true,
                                                                                info: rowData =>    <div>
                                                                                                        <Typography variant="body2">
                                                                                                            <b>Delivery Address:</b><br />
                                                                                                            {rowData.dc.split('\n').map((item, key) => {
                                                                                                                return  <span key={key}>
                                                                                                                            {item}{rowData.dc.split('\n').length === key+1 ? null : <br/>}
                                                                                                                        </span>
                                                                                                            })}
                                                                                                        </Typography>
                                                                                                    </div>,
                                                                                dataRef: 'c.n',
                                                                            },
                                                                            {
                                                                                heading: 'Status',
                                                                                field: rowData =>   <>
                                                                                                        <Tooltip
                                                                                                            title={
                                                                                                                <div>
                                                                                                                    <Typography variant="body2">{rowData.st}</Typography>
                                                                                                                </div>
                                                                                                            }
                                                                                                            placement="left"
                                                                                                        >
                                                                                                            <IconButton
                                                                                                                className={classes.iconButton}
                                                                                                            >
                                                                                                                <AllIcon 
                                                                                                                    icon={rowData.st === 'Completed' ? icons.orderCompleted : icons.orderAwaitingInvoicePayment }
                                                                                                                    button
                                                                                                                    style={{color: rowData.st === 'Completed' ? colors.green : colors.yellow}}
                                                                                                                />
                                                                                                            </IconButton>
                                                                                                        </Tooltip>
                                                                                                    </>,
                                                                                important: true
                                                                            },
                                                                            {
                                                                                heading: 'Payment Method',
                                                                                field: rowData =>   <Grid container justify="center">
                                                                                                        {_.map(rowData.ip, i => 
                                                                                                            <Grid>
                                                                                                                <Tooltip
                                                                                                                    title={<Typography variant="body2">{i}</Typography>}
                                                                                                                    placement="left"
                                                                                                                >
                                                                                                                    <IconButton className={classes.iconButton}>
                                                                                                                        <AllIcon 
                                                                                                                            icon={icons[i] || icons['unknown']}
                                                                                                                            button
                                                                                                                            style={{color: rowData.st === 'Completed' ? colors.green : colors.yellow}}
                                                                                                                            noMargin
                                                                                                                        />
                                                                                                                    </IconButton>
                                                                                                                </Tooltip>
                                                                                                            </Grid>
                                                                                                        )}
                                                                                                    </Grid>,
                                                                                important: true,
                                                                                alignment: 'center',
                                                                                sizeToContent: true,
                                                                                showTotalLabel: true,
                                                                            },
                                                                            {
                                                                                heading: 'Net',
                                                                                field: rowData => rowData.cn ? 0 - rowData.s : rowData.s,
                                                                                important: true,
                                                                                sizeToContent: true,
                                                                                dataRef: 's',
                                                                                style: rowData => rowData.cn ? {color: colors.red} : {},
                                                                                fieldFormat: 'currency',
                                                                                showTotal: true,
                                                                            },
                                                                            {
                                                                                heading: 'Vat',
                                                                                field: rowData => rowData.cn ? 0 - rowData.v : rowData.v,
                                                                                important: true,
                                                                                sizeToContent: true,
                                                                                dataRef: 'v',
                                                                                style:rowData => rowData.cn ? {color: colors.red} : {},
                                                                                fieldFormat: 'currency',
                                                                                showTotal: true,
                                                                            },
                                                                            {
                                                                                heading: 'Gross',
                                                                                field: rowData => rowData.cn ? 0 - rowData.t : rowData.t,
                                                                                important: true,
                                                                                sizeToContent: true,
                                                                                dataRef: 't',
                                                                                style: rowData => rowData.cn ? {color: colors.red} : {},
                                                                                fieldFormat: 'currency',
                                                                                showTotal: true,
                                                                            },
                                                                            {
                                                                                actions: rowData => {
                                                                                    return [
                                                                                        {name: 'Download',                   icon: 'download',      onClick: () => this.handleDownloadOpen(rowData.i, rowData.cn)},
                                                                                        {name: 'Send Order to Customer',     icon: 'envelope',      onClick: () => this.handleEmailOpen(rowData.i, rowData.cn)},
                                                                                        {name: 'View',                       icon: 'search',        link: rowData.cn ? `/returns/creditnote/${rowData.i}`          : '/sales/order/view/' + rowData.i},
                                                                                        {name: 'View this Customers Orders', icon: 'file-search',   link: rowData.cn ? `/customers/view/${rowData.c.i}/CreditNote` : '/customers/order/search/' + rowData.i}
                                                                                    ]
                                                                                }
                                                                            }
                                                                        ]}
                                                                        rows={_.orderBy(orders[date], (i) => { return i.c.n } )}
                                                                    />     
                                                                    </CardContent>
                                                                </Card>
                                                            </Grid>
                                                        )
                                                    }
                                                })}
                                            </Grid>
                                        }
                                    </Grid>
                                    </> : <Grid item xs={12} style={{padding: '1em', textAlign: 'center', fontWeight: 'bold'}}>{reduce ? 'Too Many results returned, try reducing the timescales' : 'No Results For this Date Range'}</Grid>}
                            </>}
                    </>}
            </Grid>
        )
    }
}

const mapStateToProps = state => ({
    statePersistence: state.statePersistence
})

const mapDispatchToProps = dispatch => ({
    setPersistence: (key, state)                => dispatch(setPersistence(key, state)),
    deployDialog:   (content, title, size='sm') => dispatch(deployDialog(content, title, null, size)),
    closeDialog:    ()                          => {dispatch(closeDialog())}
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SalesReportDetailed));