import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import moment               from 'moment';
import { connect }          from 'react-redux';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Hidden, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DataTable            from 'Components/Common/DataTables/CiDataTable';
import AllIcon              from 'Components/Common/Icons/AllIcon';
import LoadingCircle        from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper          from 'Components/Common/Paper/PaddedPaper';
import { FullScreenDialog } from 'Components/Common/Dialogs/FullScreenDialog';
import ViewPart             from 'Components/Parts/ViewPart/ViewPart';

import OverviewBuildDrawer from './Build/Drawer';

import { printLabel }                from 'Actions/Print/Print';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import icons                 from 'Helpers/IconHelper';
import { formatSeconds }     from 'Helpers/TimeHelper';
import { colors }            from 'Helpers/ColourHelper';
import { pdfDataFromBase64 } from 'Helpers/PDFHelper';

import logo from 'Assets/Images/clenaware_logo_icon.png';

import { create, all }              from 'mathjs';

const _math = create(all, {});

const initialState = {
    worksOrder:     {},
    subAssembly:    {},
    parts:          [],
    isLoading:      true,
    partsExpanded:  false,
}

function InfoBox({item, width}) {
    return (
        <Grid item style={{width}} >
            <Grid container spacing={3}>
                {_.map(item, ({heading, icon, value, color}, key) => 
                    <Grid item xs={12} >
                        <PaddedPaper style={{textAlign:'center'}}>
                            <AllIcon icon={icon} size='xlarge'/>
                            <Typography variant="h5" style={{textAlign:'center', paddingTop:'0.5em'}}>{heading}</Typography>
                            <Typography variant="h4" style={{textAlign:'center', color}}>{value}</Typography>
                        </PaddedPaper>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

class Overview extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.perPage = 4;
    }

    componentDidMount() {
        this.getWorksOrder();
    }

    getWorksOrder = () => {
        API.get(`/worksOrders/${this.props.worksOrder.wo_id}`)
        .then(res => {
            this.setState({
                isLoading:   false,
                worksOrder:  res.data,
                subAssembly: res.data.part,
                parts:       _.map(res.data.details, i => {
                    let detail   = _.find(res.data.build?.details,    { wobd_part_id:  i.wod_part_id });
                    let pick     = _.find(res.data.pick?.details,     { wopd_part_id:  i.wod_part_id });
                    let shortage = _.find(res.data.shortage?.details, { wossd_part_id: i.wod_part_id });

                    let picked      = 0;
                    let notRequired = false;
                    let buildAble   = res.data.wo_qty;

                    let perBuild = _math.divide(i.wod_qty_required, res.data.wo_qty);

                    notRequired = (pick?.wopd_status === 'Not Required' || shortage?.wossd_status === 'Not Required');

                    picked    = (shortage?.wossd_qty_picked || pick?.wopd_picked);
                    buildAble = notRequired ? res.data.wo_qty :  _math.floor(_math.round(_math.divide(picked,perBuild), 2)) ;

                    return _.assign({
                        ...i.part,
                        required:       i.wod_qty_required || 0,
                        picked:         picked,
                        notRequired:    notRequired,
                        perBuild:       perBuild,
                        buildAble:      buildAble,
                        built:          0,
                        used:           detail?.wobd_stock_used    || 0,
                        skipped:        detail?.wobd_stock_skipped || 0,
                    })
                }),
                partsExpanded: res.data.wo_status === 'Build' && !( res.data.shortage && !res.data.shortage?.woss_complete) ? false : true
            })
        });
    }

    refresh = () => {
        this.getWorksOrder();
        this.props.refresh();
    }

    getBoxStats = () => {
        const { worksOrder, subAssembly, parts } = this.state;
        
        const isBuild = worksOrder.wo_status === 'Build' && !( worksOrder.shortage && !worksOrder.shortage?.woss_complete) ? true : false;
        const isComplete = worksOrder.wo_status === 'Completed';

        const pickPercentMax = 30, buildPercentMax = 60, buildReviewPercentMax = 10;

        let stateBox = {
            buildStage:     0,
            buildProgress:  worksOrder.wo_status === 'Review' ? 100 : 0,

            totalBuildTime: '00:00:00',

            pick:           0,
            pickProgress:   0,

            orderProgress:  0,

            required:       0,
            requiredQty:    0,

            nr:             0,
            nrQty:          0,

            nrAdd:          0,
            nrAddQty:       0,

            pick:           0,
            pickQty:        0,

            wo:             0,
            woQty:          0,

            add:            0,
            addQty:         0,

            ret:            0,
            retQty:         0,

            total:          0,
            totalQty:       0,
        }

        if (worksOrder.pick){
            let shortProgressSub = worksOrder.shortage ? _.sumBy(worksOrder.shortage.details, 
                    i => i.wossd_status === 'Not Required' ? parseFloat(i.wossd_qty_required) : parseFloat(i.wossd_qty_picked)
                ) : 0;
            let pickProgressSub = _.sumBy(worksOrder.pick.details, i => {
                switch(i.wopd_status){
                    case 'Not Required':
                        return parseFloat(i.wopd_required);
                    case 'Partial Pick':
                    case 'Stock Missing':
                        return shortProgressSub ? 0 : parseFloat(i.wopd_picked);
                    default:
                        return parseFloat(i.wopd_picked);
                }
            });

            stateBox.pickProgress = parseInt( ( shortProgressSub + pickProgressSub ) / _.sumBy(worksOrder.pick.details,i=>parseFloat(i.wopd_required)) * 100 );
        }

        if (isBuild) {

            stateBox.buildStage     = worksOrder.build.current_stage;

            let buildProgressPercentile = 100 / (worksOrder.build.stages.length * parseFloat(worksOrder.wo_qty));
            
            stateBox.buildProgress  = (buildProgressPercentile * parseFloat(worksOrder.wo_qty)) * (stateBox.buildStage?.instruction?.sais_number - 1);
            stateBox.buildProgress += stateBox?.buildStage?.wobs_first_pass ? buildProgressPercentile : 0;

            stateBox.buildStage     = stateBox.buildStage?.instruction?.sais_number || 0;

            stateBox.totalStages    = worksOrder.build.stages.length;

        }

        if (isComplete) {
            stateBox.buildProgress = 100;
        }
        
        stateBox.totalBuildTime = formatSeconds(_.sumBy(worksOrder?.build?.time_sheets, r => !r.wobt_end ? 0 : parseInt(moment(r.wobt_end).diff(moment(r.wobt_start),'seconds'))), true, true, true)
        
        stateBox.required    = worksOrder?.details?.length;
        stateBox.requiredQty = parseFloat(_.sumBy(worksOrder?.details, i=>parseFloat(i.wod_qty_required)));

        stateBox.wo    = _.uniqBy(worksOrder?.write_offs, i => parseFloat(i.wobwo_part_id)).length;
        stateBox.woQty = _.sumBy(worksOrder?.write_offs, i=>parseFloat(i.wobwo_qty));

        let nrAdd = [];

        _.each(worksOrder?.pick?.details, i => {
            let shortage = _.find(worksOrder?.shortage?.details, { wossd_part_id: i.wopd_part_id });
            if (i.wopd_status === 'Not Required'){
                nrAdd.push(i.wopd_part_id);
                stateBox.nr++;
                stateBox.nrQty += parseFloat(i.wopd_required);
            } else {
                switch(i.wopd_status){
                    case 'Complete':
                        stateBox.pick++;
                        stateBox.pickQty += parseFloat(i.wopd_picked);
                        break;
                    case 'Partial Pick': 
                        stateBox.pick++;
                        stateBox.pickQty += parseFloat(i.wopd_picked);
                        break;
                    case 'Pending':
                    case 'Stock Missing':
                        break;
                }

                if (shortage) {
                    switch (shortage.wossd_status){
                        case 'Not Required':
                            nrAdd.push(i.wopd_part_id);
                            stateBox.nr++;
                            stateBox.nrQty += parseFloat(i.wopd_required);
                            break;
                        default:
                            if (shortage.wossd_status !== 'Pending' && i.wopd_status === 'Stock Missing'){ stateBox.pick++; }
                            stateBox.pickQty += parseFloat(parseFloat(shortage.wossd_qty_picked) - parseFloat(i.wopd_picked));
                            break;
                    }
                }
            }
        });

        if (worksOrder.additionals) {
            stateBox.add    = _.uniqBy(worksOrder.additionals, 'wobas_part_id').length;
            stateBox.addQty = _.sumBy(worksOrder.additionals, i => parseFloat(i.wobas_qty));
            _.each(_.uniqBy(worksOrder.additionals, 'wobas_part_id'), i =>{
                if (nrAdd.includes(i.wobas_part_id)){
                    stateBox.nrAdd++;
                    stateBox.nrAddQty += parseFloat(i.wobas_qty);
                }
            })
        }

        if (worksOrder.returns) {
            stateBox.ret    = _.uniqBy(worksOrder.returns, 'wobsr_part_id').length;
            stateBox.retQty = _.sumBy(worksOrder.returns, i => parseFloat(i.wobsr_qty));
        }

        stateBox.total    = stateBox.pick + stateBox.nrAdd + _.filter(_.uniqBy(worksOrder.additionals, 'wobas_part_id'), j => !_.map(worksOrder.details, d => d.wod_part_id).includes(j.wobas_part_id))?.length;
        stateBox.totalQty = stateBox.pickQty + stateBox.addQty - stateBox.retQty;


        stateBox.orderProgress = isComplete ? 100 : (
            ( pickPercentMax * ( stateBox.pickProgress / 100 ) ) +
            ( isNaN(stateBox.buildProgress) || !stateBox.buildProgress ? 0 : ( buildPercentMax * (stateBox.buildProgress / 100) ) ) +
            0
        );

        let ret = []
        ret.push(
            [
                { heading: 'Order Progress', value: `${parseInt(stateBox.orderProgress)}%`,      icon: icons.star },
                { heading: 'Build Progress', value: `${parseInt(stateBox.buildProgress || 0)}%`, icon: icons.percent } 
            ]
        )

        ret.push(
            [
                { heading: 'Build Stage',    value: isBuild ? `${parseInt(stateBox.buildStage)}/${parseInt(stateBox.totalStages)}` : 
                    (stateBox.pickProgress === 100 ? `${worksOrder.build.stages.length}/${worksOrder.build.stages.length}` : 'Not Started'), icon: icons.list },
                { heading: 'Build Time',     value: stateBox.totalBuildTime,                                              icon: icons.alarmClock } 
            ]
        )

        ret.push(
            [
                { heading: 'Required Parts', value: parseInt(stateBox.required),                  icon: icons.worksOrder },
                { heading: 'Required Qty',   value: parseFloat(stateBox.requiredQty).toFixed(3), icon: icons.worksOrder } 
            ]
        )

        ret.push(
            [
                { heading: 'Picked Parts', value: parseInt(stateBox.pick),                  icon: icons.picking },
                { heading: 'Picked Qty',   value: parseFloat(stateBox.pickQty).toFixed(3),  icon: icons.picking } 
            ]
        )

        ret.push(
            [
                { heading: 'Additional Parts', value: parseInt(stateBox.add),                       icon: icons.plus, color: colors.green},
                { heading: 'Additional Qty',   value: `+${parseFloat(stateBox.addQty).toFixed(3)}`, icon: icons.plus, color: colors.green}
            ]
        )

        ret.push(
            [
                { heading: 'Returned Parts', value: parseInt(stateBox.ret),                       icon: icons.minus, color: colors.orange},
                { heading: 'Returned Qty',   value: `-${parseFloat(stateBox.retQty).toFixed(3)}`, icon: icons.minus, color: colors.orange} 
            ]
        )

        ret.push(
            [
                { heading: 'Written Off Parts', value: parseInt(stateBox.wo),                 icon: icons.broken, color: colors.red },
                { heading: 'Written Off Qty',   value: parseFloat(stateBox.woQty).toFixed(3), icon: icons.broken, color: colors.red } 
            ]
        )

        ret.push(
            [
                { heading: 'Total Parts', value: parseInt(stateBox.total),                 icon: icons.total },
                { heading: 'Total Qty',   value: parseFloat(stateBox.totalQty).toFixed(3), icon: icons.total } 
            ]
        )

        return ret;

    }

    downloadPartLabel = (id, part_number) => () => {
        API.get('/parts/' + id + '/stockLabel',{
            params: {
              type: 'partLabel'
            }
        })
        .then(result => {
            if(result.data) {
                this.props.printLabel(pdfDataFromBase64(result.data.pdf), 'part_label_'+part_number+'.pdf');
            }
        });
    }

    render(){

        const { worksOrder, subAssembly, isLoading, parts, partsExpanded } = this.state;
        const isBuild = worksOrder.wo_status === 'Review' || (worksOrder.wo_status === 'Build' && !( worksOrder.shortage && !worksOrder.shortage?.woss_complete )) ? true : false;
        const boxStats = this.getBoxStats();

        if (isLoading) return ( <LoadingCircle /> )

        let jigs = [];
        _.each(worksOrder.part.instructions.stages, j => { 
            jigs = [...jigs, ..._.map(j.jigs, i => ({...i.jig, stage: j.sais_name}))]
        })

        let allParts =  [   ..._.map(worksOrder.details, i => {

                                let required         = 0;
                                let additional       = 0;
                                let returned         = 0;
                                let writeoff         = 0;
                                let writeoffReturned = 0;
                                let perBuild         = 0;
                                let picked           = 0;
                                let notRequired      = 0;
                                let pickStatus       = '';

                                if (!!worksOrder.build) {
                                    _.each(worksOrder.build?.stages, s => {
                                        let detail = _.find( s.instruction.parts, { saisp_part_id: i.part.part_id });
                                        if (detail) {
                                            required += parseFloat(detail.saisp_qty) * parseFloat(worksOrder.wo_qty);
                                            perBuild += parseFloat(detail.saisp_qty);
                                        }
                                    });
                                } else {
                                    let detail = _.find( worksOrder.details, { wod_part_id: i.part.part_id });
                                    required = parseFloat(detail.wod_qty_required);
                                    perBuild = parseFloat( parseFloat(detail.wod_qty_required) / parseFloat(worksOrder.wo_qty) );
                                }

                                _.each(_.filter(worksOrder.additionals, {wobas_part_id: i.part.part_id}), i => {
                                    additional += parseFloat(i.wobas_qty);
                                });

                                _.each(_.filter(worksOrder.returns, {wobsr_part_id: i.part.part_id}), i => {
                                    returned += parseFloat(i.wobsr_qty);
                                });

                                _.each(_.filter(worksOrder.write_offs, {wobwo_part_id: i.part.part_id}), i => {
                                    writeoff += parseFloat(i.wobwo_qty);
                                    writeoffReturned += parseFloat(i.wobwo_picked);
                                });

                                if (worksOrder.pick){
                                    const pickDetail = _.find(worksOrder.pick?.details, {wopd_part_id: i.part.part_id});
                                    notRequired = (pickDetail.wopd_status == 'Not Required')
                                    picked      = notRequired ? 0 : parseFloat(pickDetail.wopd_picked);
                                    pickStatus  = pickDetail.wopd_status;
                                    if (worksOrder.shortage){
                                        const shortDetail = _.find(worksOrder.shortage.details, {wossd_part_id: i.part.part_id});
                                        if (shortDetail){
                                            notRequired = (shortDetail.wossd_status == 'Not Required');
                                            picked = notRequired ? 0 : parseFloat(shortDetail.wossd_qty_picked);
                                            pickStatus  = shortDetail.wossd_status;
                                            if (pickStatus === 'Pending') {
                                                pickStatus = parseFloat(shortDetail.wossd_qty_picked) > 0 ? 'Partial' : 'Pending';
                                            }
                                        }
                                    }
                                }

                                return { 
                                    id               : i.part.part_id,
                                    img              : i?.part.default_image?.file_path,
                                    number           : i.part.part_number, 
                                    desc             : i.part.part_description,
                                    picked           : picked,
                                    perBuild         : perBuild, 
                                    required         : required, 
                                    additional       : additional, 
                                    returned         : 0 - returned, 
                                    writeoff         : writeoff,
                                    total            : picked + additional - returned  + writeoffReturned,
                                    notRequired,
                                    pickStatus
                                }
                            }),
                            ..._.map(_.uniqBy(_.filter(worksOrder.additionals, i => !_.find(worksOrder.details, {wod_part_id:i.wobas_part_id})),'wobas_part_id'), i => {

                                let required         = 0;
                                let additional       = 0;
                                let returned         = 0;
                                let writeoff         = 0;
                                let writeoffReturned = 0;
                                let perBuild         = 0;
                                let picked           = 0;

                                _.each(_.filter(worksOrder.additionals, {wobas_part_id: i.part.part_id}), s => {
                                    additional += parseFloat(s.wobas_qty);
                                })

                                _.each(_.filter(worksOrder.returns, {wobsr_part_id: i.part.part_id}), i => {
                                    returned += parseFloat(i.wobsr_qty);
                                });

                                _.each(_.filter(worksOrder.write_offs, {wobwo_part_id: i.part.part_id}), i => {
                                    writeoff += parseFloat(i.wobwo_qty);
                                    writeoffReturned += parseFloat(i.wobwo_picked);
                                });


                                return { 
                                    id               : i.part.part_id,
                                    img              : i.part?.default_image?.file_path,
                                    number           : i.part.part_number, 
                                    desc             : i.part.part_description,
                                    picked           : picked,
                                    perBuild         : perBuild, 
                                    required         : required, 
                                    additional       : additional, 
                                    returned         : 0 - returned, 
                                    writeoff         : writeoff,
                                    total            : picked + additional - returned + writeoffReturned,
                                    notRequired      : false,
                                }
                            }),
                        ]

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <PaddedPaper style={{marginBottom:0, padding: 17, marginTop:'1em'}}>
                        <Grid container spacing={2} xs={12} style={{justifyContent: 'space-between'}}>
                            <Grid item container xs={8} spacing={2} style={{justifyContent: 'flex-start', alignItems: 'center'}}>
                                <Grid item>
                                    <img src={subAssembly?.default_image?.thumbnail_file_path || logo} alt={subAssembly.part_description} style={{maxHeight:50, maxWidth:50}} />
                                </Grid>
                                <Grid item >
                                    Sub Assembly:
                                    <Typography variant='h5' style={{textAlign: 'left', marginTop:'auto'}}>{subAssembly?.part_number} - {subAssembly?.part_description}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={4} spacing={2} style={{justifyContent: 'center'}}>
                                <Grid item container xs={12} spacing={2} style={{justifyContent: 'space-evenly'}}>
                                    <Grid item>
                                        Required
                                        <Typography variant='h5' style={{textAlign: 'center', fontWeight: 'bold', marginTop: 4}}>{worksOrder.wo_qty}</Typography>
                                    </Grid>
                                    <Grid item >
                                        <br/>
                                        <AllIcon size='large' icon={'slash-forward'} noMargin />
                                    </Grid>
                                    <Grid item>
                                        Buildable
                                        <Typography variant='h5' style={{textAlign: 'center', fontWeight: 'bold', marginTop: 4}}>
                                            {parseFloat(_.minBy(parts, 'buildAble').buildAble).toFixed(3)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaddedPaper>
                </Grid>
                <Hidden mdDown>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={3}>
                            {_.map(boxStats, i => <InfoBox item={i} width={`${100 / ( boxStats.length > 4 ? 4 : boxStats.length ) }%`} />)}
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid item xs={12} lg={12}>
                        <Grid container spacing={3}>
                            {_.map(boxStats, i =>  <InfoBox item={i} width={`50%`} />)}
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs={12} lg={12}>
                    <ExpansionPanel expanded={ partsExpanded }>
                        <ExpansionPanelSummary onClick={()=>this.setState({partsExpanded: !partsExpanded})} expandIcon={<ExpandMoreIcon />}>
                            <Typography>Parts</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'id',
                                    sticky: true,
                                    showTotals:   true,
                                    totalStyle:  {fontWeight: 'bold'},
                                }}
                                columns={[
                                    {
                                        heading:       '',
                                        field:         'img',
                                        fieldFormat:   'image',
                                        sizeToContent: true,
                                        alignment:     'center',
                                    },
                                    {
                                        heading:       'Part / Description',
                                        field:         i => <>{i.number} <br/> {i.desc}</>,
                                        sizeToContent: true,
                                        truncate:      true,
                                    },
                                    {
                                        heading:       'Per Build',
                                        field:         'perBuild',
                                        sizeToContent: true,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'center',
                                        showTotal:      true,
                                    },
                                    {
                                        heading:       'Required',
                                        field:         'required',
                                        sizeToContent: true,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'center',
                                        showTotal:      true,
                                    },
                                    {
                                        heading:       'Picked',
                                        field:         i => i.notRequired ? 'Not Required' : parseFloat(i.picked).toFixed(3),
                                        sizeToContent: true,
                                        alignment:     'center',
                                        style:         i => ({fontWeight: i.notRequired && 'bold'}),
                                        showTotal:      true,
                                        overwriteTotal: <>{parseFloat(_.sumBy(allParts, i => i.notRequired ? 0 : parseFloat(i.picked) )).toFixed(3)}</> ,
                                    },
                                    {
                                        heading:       'Additional',
                                        field:         'additional',
                                        sizeToContent: true,
                                        fieldFormat:   'realNumber:colorNumbers:decimal:3',
                                        alignment:     'center',
                                        style:         i => ({color: parseFloat(i.additional) > 0 && colors.green}),
                                        showTotal:      true,
                                        overwriteTotal: <span style={{color: colors.green}}>+{parseFloat(_.sumBy(allParts, i => parseFloat(i.additional) )).toFixed(3)}</span> ,
                                    },
                                    {
                                        heading:       'Returned',
                                        field:         'returned',
                                        sizeToContent: true,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'center',
                                        style:         i => ({color: parseFloat(i.returned) < 0 && colors.orange}),
                                        showTotal:      true,
                                        overwriteTotal: <span style={{color: colors.orange}}>{parseFloat(_.sumBy(allParts, i => parseFloat(i.returned) )).toFixed(3)}</span> ,
                                    },
                                    {
                                        heading:       'Written Off',
                                        field:         'writeoff',
                                        sizeToContent: true,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'center',
                                        style:         i => ({color: parseFloat(i.writeoff) > 0 && colors.red}),
                                        showTotal:      true,
                                        overwriteTotal: <span style={{color: colors.red}}>{parseFloat(_.sumBy(allParts, i => parseFloat(i.writeoff) )).toFixed(3)}</span> ,
                                    },
                                    {
                                        heading:       'Total',
                                        field:         'total',
                                        sizeToContent: true,
                                        fieldFormat:   'decimal:3',
                                        alignment:     'center',
                                        showTotal:      true,
                                    },
                                    {
                                        heading:        '',
                                        field:          rowData => 
                                            <>
                                                {!!rowData.pickStatus && 
                                                    <AllIcon noMargin icon={icons.picking} color={
                                                        (rowData.pickStatus === 'Complete' && colors.green) || (rowData.pickStatus === 'Not Required' ?
                                                            colors.disabled : ( rowData.pickStatus === 'Partial' ? colors.orange : colors.red ))
                                                } tooltip={rowData.pickStatus}/>}
                                                {!!rowData.additional && !rowData.pickStatus &&
                                                    <AllIcon noMargin icon={icons.plus} color={colors.green} tooltip={'Additional'}/>
                                                }
                                            </>
                                        
                                        ,
                                        alignment:      'center',
                                        sizeToContent:  true,
                                    },
                                    {
                                        actions: i => ([
                                            {
                                                name: 'View',      
                                                icon: icons.search,        
                                                onClick: () => {
                                                    this.props.deployDialog(
                                                        <FullScreenDialog 
                                                            open={1} 
                                                            onClose={this.props.closeDialog} 
                                                            title="Part Information" 
                                                            content={<ViewPart id={i.id} dialog={true} />}
                                                        />, 
                                                        "", 
                                                        null, 
                                                        'fs'
                                                    )
                                                }
                                            },
                                            {
                                                name: 'Print part label',
                                                icon: icons.print,
                                                onClick: this.downloadPartLabel(i.id, i.number),
                                            }
                                        ])
                                    }
                                ]}
                                rows={_.orderBy(allParts, 'number')}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Jigs</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <DataTable
                                config={{
                                    key: 'part_id',
                                }}
                                columns={[
                                    {
                                        field:          'default_image.file_path',
                                        fieldFormat:    'image',
                                        sizeToContent:  true
                                    },
                                    {
                                        heading:       'Jig',
                                        field:         'part_number',
                                        sizeToContent: true,
                                    },
                                    {
                                        heading: 'Description',
                                        field:   'part_description',
                                    },
                                    {
                                        actions: i => ([
                                            {
                                                name: 'View',      
                                                icon: icons.search,        
                                                onClick: () => {
                                                    this.props.deployDialog(
                                                        <FullScreenDialog 
                                                            open={1} 
                                                            onClose={this.props.closeDialog} 
                                                            title="Jig Information" 
                                                            content={<ViewPart id={i.part_id} />}
                                                        />, 
                                                        "", 
                                                        null, 
                                                        'fs'
                                                    )
                                                }
                                            },
                                        ])
                                    }
                                ]}
                                rows={_.orderBy(jigs, 'part_number')}
                            />
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
                {(isBuild || !!worksOrder.wo_completed) && 
                    <Grid item container xs={12} style={{justifyContent: 'space-between'}}  key={`${_.filter(worksOrder.build.stages, {wobs_first_pass: 1}).length}-${_.filter(worksOrder.build.stages, {wobs_complete: 1}).length}`}>
                        {_.map(worksOrder.build.stages, i => <OverviewBuildDrawer stage={i} worksOrder={worksOrder} refresh={this.refresh} complete={!!worksOrder.wo_completed} />)}
                    </Grid>
                }
            </Grid>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size) => dispatch(deployDialog(content, title, variant, size)),
    closeDialog:        ()                              => dispatch(closeDialog()),
    printLabel:         (data, file)                    => dispatch(printLabel(data, file)),
})

export default connect(null, mapDispatchToProps)(Overview)