import API from 'API';
import _ from 'lodash';
import moment from 'moment';
import React,{ Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Button, ButtonGroup, Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, IconButton, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import BackButton from 'Components/Common/Buttons/BackButton';
import CopyToClipboard from 'Components/Common/CopyToClipboard/CopyToClipboard';
import DataTable from 'Components/Common/DataTables/CiDataTable';
import ConfirmationDialog from 'Components/Common/Dialogs/ConfirmationDialog';
import FormDialog from 'Components/Common/Dialogs/FormDialog';
import ViewEngineerMedia from 'Components/Common/EngineerMedia/ViewEngineerMedia';
import AllIcon from 'Components/Common/Icons/AllIcon';
import FALightIcon from 'Components/Common/Icons/FontAwesome/FALightIcon';
import EmailField from 'Components/Common/Inputs/EmailField';
import Textarea from 'Components/Common/Inputs/Textarea';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import Map from 'Components/Common/Map/Map';
import MapMarker from 'Components/Common/Map/MapMarker';
import TabBar from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import EngineerJobSheets from 'Components/Customers/ViewCustomer/EngineerJobSheets';
import OrderSearch from 'Components/Customers/ViewCustomer/OrderSearch';
import CallHistory from 'Components/Phone/CallHistory';
import Reminders from 'Components/Reminders/Reminders/Reminders';

import { Dial, DialAdornment } from 'Functions/MiscFunctions';
import { colors } from 'Helpers/ColourHelper';
import { formatValidationErrors } from 'Helpers/ErrorHelper';
import icons from 'Helpers/IconHelper';
import { formatSeconds } from 'Helpers/TimeHelper';

import addressSitePin from 'Assets/Images/markers/addressSite.png';
import clenyPin from 'Assets/Images/markers/cleny.png';

import UpdateAccessTimes from './UpdateAccessTimes';
import UpdateOpeningTimes from './UpdateOpeningTimes';

import { deployConfirmation } from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import ViewEmails from 'Components/Common/Email/ViewEmails/ViewEmails';

import QuotationSearch from 'Components/Customers/ViewCustomer/QuotationSearch';
import IconHelper from 'Helpers/IconHelper';
import AddReminderDialog from './AddReminderDialog';
import ViewPartsSold from 'Components/Customers/ViewCustomer/ViewPartsSold';

const initialState = {
    customer: {},
    address: {
        address_type: '',
        address_name: '',
        address_line_one: '',
        address_line_two: '',
        address_line_three: '',
        address_town: '',
        address_postcode: '',
        county: '',
        country: '',
        address_phone: '',
        address_email: '',
        address_website: '',
        address_customer_id: ''
    },
    redirect: false,
    showDialog:false,
    formErrors:'',
    formData: {
        customer: '',
        name: '',
        position: '',
        telephone: '',
        mobile: '',
        email: '',
        associatedAddresses: [],
        unsubscribe: false,
        validation: false
    },
    confirmationOpen: false,
    addressId: 0,
    isLoading: true,
    currentTab: 'Address',
    couriers: [],
    mapFormData: {
        show: false,
        center: null,
        hotels: '',
        parking: '',
        chargingPoints: '',
        Site: '',
    },
    addressNotes: '',
    access: {
        viewContact:   false,
        updateContact: false,
        addOrder:      false,
        addQuote:      false,
        addContact:    false,
        addCreditNote: false,
        updateAddress: false
    },
    importantNoteShown: false,
    tabAccess: {
        'Address'       : true, 
        'Orders'        : false, 
        'Media'         : false, 
        'Job Sheets'    : false, 
        'Courier Zones' : false,
        'Phone History' : false
    },
}

const styles = theme => ({
    disabledText: {
        color: 'black !important',
    }
});

class ViewCustomerAddress extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.state.addressId = this.props.match.params.id;
    }
    componentDidMount(){ 
        this.getCustomerAddress(this.props.match.params.id); 
        this.checkAccess();
        this.getTabAccess();
    }

    getTabAccess = () => {
        let tabs = {
            'Orders'        : 'view-customer-address-tab-orders', 
            'Quotes'        : 'view-customer-address-tab-quotes', 
            'Media'         : 'view-customer-address-tab-media', 
            'Job Sheets'    : 'view-customer-address-tab-job-sheets', 
            'Courier Zones' : 'view-customer-address-tab-courier-zones',
            'Phone History' : 'view-customer-address-phone-history',
            'Emails'        : 'view-customer-address-emails',
            'Reminders'     : 'view-customer-address-reminders',
            'Sold Parts'    : 'view-customer-address-sold-parts',
        }
        Promise.all(
            _.map(_.values(tabs), i => API.get(`/staff/my/access/check/${i}`))
        ).then(res => {
            let tabAccess = this.state.tabAccess;
            let keys = _.keys(tabs);
            _.each(res, (r, idx) => {
                tabAccess[keys[idx]] = r.data ? r.data.has_access : false;
            })
            this.setState({tabAccess}, ()=>{
                this.state.tabAccess['Courier Zones'] && this.getCouriers();
            })
        })
    }

    componentDidUpdate(){ 
        if (this.state.addressId != this.props.match.params.id){
            this.setState({...initialState, addressId: this.props.match.params.id}, () => {
                this.getCustomerAddress(this.props.match.params.id); 
                this.checkAccess();
                this.getTabAccess();
            });
        }
    }

    checkAccess = () => {
        Promise.all([ 
            API.get('/staff/my/access/check/view-customer-contact'),
            API.get('/staff/my/access/check/update-customer-contact'), 
            API.get('/staff/my/access/check/add-order'),
            API.get('/staff/my/access/check/add-quotation'),
            API.get('/staff/my/access/check/add-customer-contact'),
            API.get('/staff/my/access/check/add-credit-note'),
            API.get('/staff/my/access/check/update-customer-address'),
        ])
        .then(([viewContact, updateContact, addOrder, addQuote, addContact, addCreditNote, updateAddress]) =>  {
            this.setState({
                access: {
                    ...this.state.access,
                    viewContact:   viewContact.data   ? viewContact.data.has_access   : false,
                    updateContact: updateContact.data ? updateContact.data.has_access : false,
                    addOrder:      addOrder.data      ? addOrder.data.has_access      : false,
                    addQuote:      addQuote.data      ? addQuote.data.has_access      : false,
                    addContact:    addContact.data    ? addContact.data.has_access    : false,
                    addCreditNote: addCreditNote.data ? addCreditNote.data.has_access : false,
                    updateAddress: updateAddress.data ? updateAddress.data.has_access : false,
                }
            });
        });
    }

    getCouriers = () => {
        API.get(`/couriers`).then(res => { this.setState({ couriers: res.data.couriers }); })
    }

    getCustomerAddress = (addressId) => {
        API.get(`/customers/addresses/${addressId}`, { params: { withMarkers: true } })
        .then((result) => {
            if (result.data.errors) {
                this.setState({ redirect: true })
            } else if (result.data){

                let nearest = {};
                _.each(result.data.address_nearest_markers, (i, _i) => {
                    nearest[_i] = _.map(i, (j, _j) => j);
                });

                this.setState({
                    address: result.data,
                    nearest: nearest,
                    formData:{
                        ...this.state.formData,
                        customer: result.data.address_customer_id,
                        associatedAddresses: [addressId],
                    },
                    mapFormData: {
                        ...this.state.mapFormData,
                        hotels: result.data.address_hotel_notes,
                        parking: result.data.address_parking_notes,
                        chargingPoints: result.data.address_chrarging_notes,
                    },
                    addressNotes: result.data.address_notes,
                    isLoading: false 
                },this.getCustomer);
            }
        });
    };

    handleToggleDialog = () =>{
        this.setState({showDialog: !this.state.showDialog});
    }

    handleChange = (e) => {
        this.setState({
            formData: {
                ...this.state.formData,
                [e.target.name]: e.target.value
            }
        });
    }

    submit = () => {
        this.setState({
            isLoading: true
        },
        () => {
            API.post(`/customers/contacts`, this.state.formData)
            .then((result) => {
                if(result.data.errors && result.data.errors.length > 0){           
                    this.setState({
                        formErrors: formatValidationErrors(result.data.errors),
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        formData: {...initialState.formData, 
                            customer            :this.state.formData.customer,  
                            associatedAddresses :this.state.formData.associatedAddresses,  
                        },
                        isLoading: false,
                        showDialog:false,
                        address: {...this.state.address, 
                            active_contacts:[...this.state.address.active_contacts, 
                                {
                                    contact_name        : this.state.formData.name,
                                    contact_position    : this.state.formData.position,
                                    contact_telephone   : this.state.formData.telephone,
                                    contact_mobile      : this.state.formData.mobile,
                                    contact_email       : this.state.formData.email
                                } 
                            ]}
                    });
                }
            });
        });
    }



    handleConfirmationOpen = (e) => {
        this.setState({
            confirmationOpen: true,
        });
    };

    handleConfirmationClose = () => {
        this.setState({ 
            confirmationOpen: false 
        });
    };

    handleConfirmationSuccess = () => {
        this.setState({ 
            confirmationOpen: false 
        });
        this.submit();
    }

    handleTabChange = (event, value) => {
        this.setState({ currentTab: value });
    };

    handleMapFormChange = field => (e) => {
        this.setState({
            mapFormData: {
                ...this.state.mapFormData,
                [field]: e.target.value
            }
        });
    }

    saveMapNotes = () => {
        API.post(`/customers/addresses/${this.props.match.params.id}/updateMapNotes`, this.state.mapFormData).then(res => this.setState({mapFormData: {...this.state.mapFormData, show: false}}));
    }

    saveNote = () => {
        API.post(`/customers/addresses/${this.props.match.params.id}/updateNotes`, {addressNotes: this.state.addressNotes})
        .then(res => this.getCustomerAddress(this.props.match.params.id));
    }

    handleChangeNote = (e) => {
        this.setState({
            addressNotes: e.target.value
        });
    }

    getCustomer = () => {
        API.get(`/customers/${this.state.address.address_customer_id}`)
        .then(result => {
            this.setState({customer: result.data},() => {
                if (!this.state.importantNoteShown && result.data.cust_important_info) this.displayImportantInfo();
            });
        });
    }

    displayImportantInfo = () => {
        this.setState({importantNoteShown: true}, () => {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.customer?.cust_important_info && this.state.customer?.cust_important_info.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
            </>
            , 'Important Information', 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        });
    }

    handleEmailValidation = (value) => {
        this.setState({
            formData:{
                ...this.state.formData,
                validation: value
            }
        });
    }

    changeAddressSubscribeStatus = () => {
        API.post(`/customers/addresses/${this.props.match.params.id}/subscribe`)
        .then(()=>this.getCustomerAddress(this.props.match.params.id))
    }

    addReminder = () => {
        this.props.deployDialog(<AddReminderDialog callback = {()=>this.getCustomerAddress(this.props.match.params.id)} addressId = {this.props.match.params.id} />,'Add Reminder', 'xs')
    }

    render() {
        const { address, showDialog, formErrors, formData, isLoading, currentTab, couriers, mapFormData, addressNotes, access, customer, tabAccess } = this.state;
        const { classes } = this.props;

        const tabItems = ['Address', 'Orders', 'Quotes', 'Media', 'Job Sheets', 'Courier Zones', 'Phone History', 'Emails', 'Reminders', 'Sold Parts'];

        const MapNoteSection = ({mapCategory, formDataName} ) => {
            return (
                <>
                    <Grid container style={{marginTop: '1em', alignItems: 'center'}}>
                        <Grid item xs={12} style={{display: 'flex', alignItems: 'flex-start'}}>
                            <IconButton
                                style={{padding: 0}}
                                onClick={()=>{
                                    this.setState({mapFormData:{...mapFormData, center: [parseFloat(_.first(_.values(address?.address_nearest_markers[mapCategory]))?.lat), parseFloat(_.first(_.values(address?.address_nearest_markers[mapCategory]))?.lng)]}})
                                }}
                            >
                                <MapMarker icon={_.first(_.values(address?.address_nearest_markers[mapCategory]))?.icon} color={colors.primary} size={50} />
                            </IconButton>
                            <Grid container>
                                <Grid item xs={12} style={{display: 'flex'}}>
                                    <Typography style={{width: '100%'}}>{mapCategory}</Typography>
                                    <Grid item container style={{justifyContent:'flex-end', gridGap: 10}}>
                                        {_.map(_.values(address?.address_nearest_markers[mapCategory]), ( i, name) => 
                                            <Grid item>
                                                {i.category_name}
                                                <Tooltip title={<>
                                                        <Typography variant='body2'>{i.name}</Typography>
                                                        <Typography variant='body2'>{parseFloat(i.miles).toFixed(2)} Miles / {formatSeconds(i.travel * 60, true, true, false)} Hours away</Typography>
                                                        <Typography variant='body1'>{_.map(i.notes.split('\n'), j => <>{j}<br/></>)}</Typography>
                                                    </>} placement={'left'}>
                                                    <IconButton
                                                        style={{padding: 0}}
                                                        onClick={()=>{
                                                            this.setState({mapFormData:{...mapFormData, center: [parseFloat(i.lat), parseFloat(i.lng)]}})
                                                        }}

                                                    >
                                                        <MapMarker icon={i.icon} color={i.color} size={25} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item >
                                            <Typography variant='body1'>Nearest: </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body1'>
                                                {_.values(address?.address_nearest_markers[mapCategory]).length > 0 && `${_.first(_.values(address?.address_nearest_markers[mapCategory]))?.name} | ${parseFloat(_.first(_.values(address?.address_nearest_markers[mapCategory]))?.miles).toFixed(2)} Miles / ${formatSeconds(_.first(_.values(address?.address_nearest_markers[mapCategory]))?.travel * 60, true, true, false)} Hours Away`}
                                            </Typography>
                                            <Typography variant='body2' style={{fontSize: 13}}>
                                                {_.map(_.first(_.values(address?.address_nearest_markers[mapCategory]))?.notes.split('\n'), i => <>{i}<br/></>)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{position: 'relative'}}>
                            <Grid item style={{marginLeft: 'auto', position: 'absolute', top: 25, right: 0, zIndex: 88}}>
                                <AllIcon
                                    style={{padding: 0}}
                                    icon={icons.edit}
                                    noMargin
                                    onClick={()=>{
                                        this.setState({
                                            mapFormData: {
                                                ...this.state.mapFormData,
                                                show: formDataName,
                                                label: mapCategory
                                            }
                                        })
                                    }}
                                />
                            </Grid>
                            <Textarea
                                value={mapFormData[formDataName]}
                                onChange={this.handleMapFormChange(formDataName)}
                                displayOnly
                            />
                        </Grid>
                    </Grid>
                </>
            )
        }

        if (this.state.redirect === true) {
            return <Redirect to="/customers/search" />
        } else {
            if (isLoading) return <LoadingCircle />
            return (
                <Grid container style={{alignItems: 'stretch'}}>
                    <ConfirmationDialog 
                            open={this.state.confirmationOpen} 
                            success={this.handleConfirmationSuccess} 
                            close={this.handleConfirmationClose} 
                            title="Add Contact?" 
                            message="Are you sure you want to add this contact?"
                        />
                    <FormDialog 
                        open={showDialog}
                        close={this.handleToggleDialog}
                        title={'Add Contact'}
                        handleSubmit={this.handleConfirmationOpen}
                        height={400}
                        body={
                            <>
                            <Paper elevation={0} >
                                <Typography variant="p">
                                    Please Note: this will only add a new contact to the site address '{address.address_name}'
                                </Typography>
                            </Paper>
                            
                                <form noValidate autoComplete="off">
                                        <TextField
                                            id="name"
                                            name="name"
                                            label="Name *"
                                            value={formData.name}
                                            error={formErrors && formErrors['name'] && true}
                                            helperText={formErrors && formErrors['name']}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <TextField
                                            id="position"
                                            name="position"
                                            label="Position"
                                            value={formData.position}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                        />
                                        <EmailField
                                            id="email"
                                            name="email"
                                            label="Email"
                                            value={formData.email}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            fullWidth
                                            validationCallBack={this.handleEmailValidation}
                                        />
                                        <Grid container item spacing={3}>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    id="mobile"
                                                    name="mobile"
                                                    label="Mobile"
                                                    value={formData.mobile}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <TextField
                                                    id="telephone"
                                                    name="telephone"
                                                    label="Telephone"
                                                    value={formData.telephone}
                                                    onChange={this.handleChange}
                                                    margin="normal"
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id="unsubscribe"
                                                    name="unsubscribe"
                                                    label="Unsubscribe From All Marketing"
                                                    checked={formData.validation === 'unsubscribed' ? 1 : formData.unsubscribe}
                                                    onChange={()=>this.setState({formData: {...formData, unsubscribe: !formData.unsubscribe}})}
                                                    color='primary'
                                                    margin="normal"
                                                    fullWidth
                                                    disabled={formData.validation === 'unsubscribed'}
                                                />
                                            }
                                            label={'Unsubscribe From All Marketing'}
                                        />
                                    </form>
                                </>
                        }
                    />
                    <Grid item xs={4}>
                        <Typography variant="h5" style={{paddingBottom: '1.5em'}}>
                            View {address.address_type} Address
                        </Typography>
                    </Grid>
                    {!!address?.customer &&
                        <Grid item style={{marginLeft:'auto'}}>
                            <Button
                                color='primary'
                                variant='contained'
                                onClick={()=>this.props.history.push('/customers/view/'+address.address_customer_id)}
                            >View {address?.customer?.cust_name}<AllIcon white icon={IconHelper.customers} style={{marginLeft: '1em'}}/></Button>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TabBar
                            tabs={_.map(_.filter(tabItems, i => tabAccess[i]), i => ({label: i, value: i}))}
                            currentTab={currentTab} 
                            handleTabChange={this.handleTabChange}
                        />
                    </Grid>
                    {customer?.cust_name && customer?.account_status?.cas_name != 'Active - Verified' &&
                        <Grid xs={12} spacing={3} style={{padding:'1em', color:'white', backgroundColor: colors.red}}>
                            <Typography style={{fontWeight:'bold'}}>{customer?.cust_name} is {customer?.account_status?.cas_name}</Typography>
                        </Grid>
                    }
                    {currentTab === 'Reminders' &&
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Reminders addressId={this.state.addressId}/>
                            </PaddedPaper>
                        </Grid>
                    }
                    {currentTab === 'Phone History' && 
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <CallHistory customerId={address.address_customer_id} siteId={this.state.addressId} inPage />
                            </PaddedPaper>
                        </Grid>
                    }
                    {currentTab === 'Courier Zones' && 
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h6" gutterBottom>
                                    Courier Zones
                                </Typography>
                                <Grid container spacing={3}>
                                    {_.map(address.zones, r => 
                                        <Grid item xs={12}>
                                            <PaddedPaper>
                                                <Grid container spacing={3}>
                                                    <Grid item>
                                                        <img src={_.find(_.find(couriers, { cour_id: r.cz_cour_id }).file, {cour_file_type: "Logo"}).file_url} alt={_.find(couriers, { cour_id: r.cz_cour_id }).cour_name} height='30px'/>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography variant="h6" gutterBottom>{_.find(couriers, { cour_id: r.cz_cour_id }).cour_name} ({r.cz_name})</Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {_.map(r.cz_desc.split('\n'), i => <>{i}<br/></>)}
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>    
                                    )}
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    }
                    {currentTab === 'Emails' &&
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <ViewEmails addressId={this.state.addressId}/>
                            </PaddedPaper>
                        </Grid>
                    }
                    {currentTab === 'Job Sheets' && 
                        <Grid item xs={12}>
                            <PaddedPaper>
                                <Typography variant="h6" gutterBottom>
                                    Job Sheets
                                </Typography>
                                <EngineerJobSheets customerId={address.address_customer_id} siteId={this.state.addressId}/>
                            </PaddedPaper>
                        </Grid>
                    }
                    {currentTab === 'Media' &&
                        <Grid item xs={12}>
                            <PaddedPaper >
                                <Typography variant="h6" gutterBottom>
                                    Engineer Media
                                </Typography>
                                <ViewEngineerMedia siteId={this.state.addressId} customerId={address.address_customer_id}/>
                            </PaddedPaper>
                        </Grid>
                    } 
                    {currentTab === 'Orders' && 
                        <Grid item xs={12}>
                            <PaddedPaper >
                                <OrderSearch noMargin disablePaper cid={address.address_customer_id} aid={this.state.addressId}/>
                            </PaddedPaper>
                        </Grid>
                    }
                    {currentTab === 'Quotes' && 
                        <Grid item xs={12}>
                            <PaddedPaper >
                                <>
                                    <QuotationSearch noMargin disablePaper cid={address.address_customer_id} aid={this.state.addressId}/>
                                </>
                            </PaddedPaper>
                        </Grid>
                    }
                    {currentTab === 'Sold Parts' && 
                         <Grid item xs={12}>
                            <PaddedPaper >
                                <>
                                    <ViewPartsSold cid={this.state.customer.cust_id} aid={this.state.addressId}/>
                                </>
                            </PaddedPaper>
                        </Grid>
                    }
                    {currentTab === 'Address' && 
                        <Grid container item xs={12} >
                            <Grid item md={6} xs={12} style={{paddingTop: '1.5em', paddingRight: '1.5em'}}>
                                <Grid item >
                                    <PaddedPaper style={{height: '100%'}}>
                                        <Typography variant="h6">
                                            {address.address_name}
                                        </Typography>
                                        <TextField
                                            label="Address Line 1"
                                            value={address.address_line_one && address.address_line_one}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                            inputProps={{
                                                className: classes.disabledText
                                            }}
                                            InputLabelProps={{
                                                className: classes.disabledText
                                            }}
                                        />
                                        <TextField
                                            label="Address Line 2"
                                            value={address.address_line_two && address.address_line_two}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                            inputProps={{
                                                className: classes.disabledText
                                            }}
                                            InputLabelProps={{
                                                className: classes.disabledText
                                            }}
                                        />
                                        <TextField
                                            label="Address Line 3"
                                            value={address.address_line_three && address.address_line_three}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                            inputProps={{
                                                className: classes.disabledText
                                            }}
                                            InputLabelProps={{
                                                className: classes.disabledText
                                            }}
                                        />
                                        <TextField
                                            label="City / Town"
                                            value={address.address_town && address.address_town}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                            inputProps={{
                                                className: classes.disabledText
                                            }}
                                            InputLabelProps={{
                                                className: classes.disabledText
                                            }}
                                        />
                                        <TextField
                                            label="Postcode"
                                            value={address.address_postcode && address.address_postcode}
                                            inputProps={{
                                                className: classes.disabledText
                                            }}
                                            InputLabelProps={{
                                                className: classes.disabledText
                                            }}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="County"
                                            value={address.county ? address.county.county_name : ''}
                                            inputProps={{
                                                className: classes.disabledText
                                            }}
                                            InputLabelProps={{
                                                className: classes.disabledText
                                            }}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="Country"
                                            value={address.country && address.country.country_name}
                                            inputProps={{
                                                className: classes.disabledText
                                            }}
                                            InputLabelProps={{
                                                className: classes.disabledText
                                            }}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="Phone"
                                            value={address && address.address_phone}
                                            InputProps={{
                                                startAdornment: <DialAdornment number={address?.address_phone || '-'} />
                                            }}
                                            inputProps={{
                                                className: classes.disabledText
                                            }}
                                            InputLabelProps={{
                                                className: classes.disabledText
                                            }}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                        />
                                        <TextField
                                            label="Email"
                                            value={address && address.address_email}
                                            inputProps={{
                                                className: classes.disabledText
                                            }}
                                            InputLabelProps={{
                                                className: classes.disabledText
                                            }}
                                            margin="normal"
                                            fullWidth
                                            disabled
                                        />
                                        {address.address_type === 'Registered' &&
                                            <TextField
                                                label="Website"
                                                value={address && address.address_website}
                                                inputProps={{
                                                    className: classes.disabledText
                                                }}
                                                InputLabelProps={{
                                                    className: classes.disabledText
                                                }}
                                                margin="normal"
                                                fullWidth
                                                disabled
                                            />
                                        }
                                    </PaddedPaper>
                                </Grid>
                                {!!address.address_active &&
                                    <Grid item >
                                        {address.address_type === 'Accounts' &&
                                            <PaddedPaper style={{marginTop:'1.8em'}}>
                                                <Grid container item xs={12} >
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className="float-left">
                                                            Linked Sites
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <DataTable 
                                                            config={{
                                                                noHeader: true,
                                                                pagination: true,
                                                                rowsPerPage: 5
                                                            }}
                                                            columns={
                                                                [
                                                                    {
                                                                        heading:   'Site',
                                                                        field:     rowData => rowData.address_name,
                                                                        fullWidth: true,
                                                                    },
                                                                    {
                                                                        actions: rowData => { return [
                                                                            {name: 'View', icon: 'search', link: '/customers/addresses/view/'+ rowData.address_id},
                                                                            {name: 'Edit', icon: 'pen', link: '/customers/update/view/'+ rowData.address_id},
                                                                        ]},
                                                                        sizeToContent:  true,
                                                                        important:      true,
                                                                        alignment:      'right'
                                                                    },
                                                                ]
                                                            }
                                                            rows={address.linked_sites}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        }
                                        {address.address_type === 'Site' && 
                                            <>
                                                {address.linked_accounts ? 
                                                    <PaddedPaper style={{marginTop:'1.8em'}}>
                                                        <Grid container item xs={12} spacing={3} alignItems='center'>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h6" gutterBottom className="float-left">
                                                                    Linked Account
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={10} style={{padding:10}}>
                                                                <Typography variant="body1" className="float-left">
                                                                    {address.linked_accounts?.address_name }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={1} className='buttonRow' style={{margin:0}}>
                                                                <AllIcon noMargin toolTip='Update' icon='pen' link={`/customers/addresses/update/${address.linked_accounts.address_id}`} disabled={!access.updateAddress} />
                                                            </Grid>
                                                            <Grid item xs={1} className='buttonRow' style={{margin:0}}>
                                                                <AllIcon noMargin toolTip='View' icon='search' link={`/customers/addresses/view/${address.linked_accounts.address_id}`} disabled={!access.updateAddress} />
                                                            </Grid>
                                                        </Grid>
                                                    </PaddedPaper>:
                                                    <PaddedPaper style={{marginTop:'1.8em'}}>
                                                        <Grid container>
                                                            <Grid item ><AllIcon size='large' icon='shop-slash' style={{color: colors.red}} /></Grid>
                                                            <Grid item ><Typography variant="body1" style={{color: colors.red, paddingTop: 3, paddingLeft:'0.5em'}}>Account Not Linked</Typography></Grid>
                                                        </Grid>
                                                    </PaddedPaper>
                                                }
                                            </>
                                        }
                                    </Grid>
                                }
                                <Grid item xs={12} spacing={3} style={{marginTop:'1.5em'}}>
                                    <PaddedPaper>
                                        <Grid container item xs={12} spacing={3} >
                                            <Grid item>
                                                <Typography variant="h6" style={{marginBottom:12}}>
                                                    Access Times
                                                </Typography>
                                            </Grid>
                                            {access.updateAddress &&
                                                <Grid item style={{marginLeft: 'auto'}}>
                                                    <AllIcon icon={icons.edit} onClick={()=> this.props.deployDialog(<UpdateAccessTimes onSave={()=>this.getCustomerAddress(address.address_id)} address={address} />, 'Update Access Times') } noMargin />
                                                </Grid>
                                            }
                                        </Grid>
                                        {(address.access_times && address.access_times.length > 0) &&
                                            <DataTable  
                                                config={{
                                                    key: 'ca_access_id',
                                                    pagination: false
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Day',
                                                        field: rowData => rowData.ca_access_day
                                                    },
                                                    {
                                                        heading: 'Start Time',
                                                        field: rowData => moment(rowData.ca_access_start, "HH:mm:ss").format("HH:mm")
                                                    },
                                                    {
                                                        heading: 'End Time',
                                                        field: rowData => moment(rowData.ca_access_end, "HH:mm:ss").format("HH:mm")
                                                    }
                                                ]}
                                                rows={address.access_times}
                                            />
                                        }
                                        {(address.address_access_times && address.address_access_times != '') &&
                                            <Textarea
                                                displayOnly
                                                label='Access Times Notes'
                                                value={address.address_access_times}
                                                margin="normal"
                                                fullWidth
                                                rows={4}
                                            />
                                        }
                                        {!(address.access_times && address.access_times.length > 0) && !(address.address_access_times && address.address_access_times != '') &&
                                            <Typography variant="body2" >No Access Times</Typography>
                                        }
                                    </PaddedPaper>
                                </Grid>
                                <Grid item xs={12} spacing={3} style={{marginTop:'1.5em'}}>
                                    <PaddedPaper>
                                        <Grid container item xs={12} spacing={3} >
                                            <Grid item>
                                                <Typography variant="h6" style={{marginBottom:12}}>
                                                    Opening Times
                                                </Typography>
                                            </Grid>
                                            {access.updateAddress &&
                                                <Grid item style={{marginLeft: 'auto'}}>
                                                    <AllIcon icon={icons.edit} onClick={()=> this.props.deployDialog(<UpdateOpeningTimes onSave={()=>this.getCustomerAddress(address.address_id)} address={address} />, 'Update Access Times') } noMargin />
                                                </Grid>
                                            }
                                        </Grid>
                                        {(address.opening_times && address.opening_times.length > 0) &&
                                            <DataTable  
                                                config={{
                                                    key: 'ca_opening_id',
                                                    pagination: false
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Day',
                                                        field: rowData => rowData.ca_opening_day
                                                    },
                                                    {
                                                        heading: 'Start Time',
                                                        field: rowData => moment(rowData.ca_opening_start, "HH:mm:ss").format("HH:mm")
                                                    },
                                                    {
                                                        heading: 'End Time',
                                                        field: rowData => moment(rowData.ca_opening_end, "HH:mm:ss").format("HH:mm")
                                                    }
                                                ]}
                                                rows={address.opening_times}
                                            />
                                        }
                                        {(address.address_opening_times && address.address_opening_times != '') &&
                                            <Textarea
                                                label='Opening Times Notes'
                                                value={address.address_opening_times}
                                                margin="normal"
                                                fullWidth
                                                rows={4}
                                                displayOnly
                                            />
                                        }
                                        {!(address.opening_times && address.opening_times.length > 0) && !(address.address_opening_times && address.address_opening_times != '') &&
                                            <Typography variant="body2" >No Opening Times</Typography>
                                        }
                                    </PaddedPaper>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12} style={{paddingTop: '1.5em'}}>
                                <Grid item xs={12}>
                                    <PaddedPaper>
                                        <Textarea
                                            margin="none"
                                            label='Notes'
                                            value={addressNotes}
                                            onChange={this.handleChangeNote}
                                            disabled={!access.updateAddress}
                                        />
                                        {access.updateAddress &&
                                            <div className='buttonRow' style={{marginTop: 5}}>
                                                <Button onClick={this.saveNote} variant='contained' color='primary' >Save</Button>
                                            </div>
                                        }   
                                    </PaddedPaper>
                                </Grid>
                                <Grid style={{marginTop:'1.5em'}}>
                                    {!!address.address_active ?
                                        <>
                                            <PaddedPaper>
                                                <Grid container item xs={12} >
                                                    <Grid item xs={12}>
                                                        <Typography variant="h6" className="float-left" >
                                                            Quick Links
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <ButtonGroup fullWidth variant="contained"  color='primary' style={{marginTop: '1em'}}>
                                                            <Button  component="a"   href={`/sales/quotation/add/customer/site/${this.props.match.params.id}`}  disabled={!access.addQuote || ((address.customer?.cust_account_status_id === 3) ? true : false)}>
                                                                <FALightIcon icon={'handshake'} style={{color: (address.customer?.cust_account_status_id === 3) ? 'rgba(0, 0, 0, 0.26)' : '#fff' }}/>
                                                                Add Quotation
                                                            </Button>
                                                            <Button  component="a"  href={`/sales/order/add/customer/site/${this.props.match.params.id}`} disabled={!access.addOrder || ((address.customer?.cust_account_status_id === 3 || address.customer?.cust_account_status_id === 2) ? true : false)}>
                                                                <FALightIcon icon={'handshake'} style={{color: (address.customer?.cust_account_status_id === 3 || address.customer?.cust_account_status_id === 2) ? 'rgba(0, 0, 0, 0.26)' : '#fff' }}/>
                                                                Add Order
                                                            </Button>
                                                        </ButtonGroup>
                                                        <ButtonGroup fullWidth variant="contained"  color='primary' style={{marginTop: '1em'}}>
                                                            <Button component="a" href={`/returns/creditnote/add/customer/site/${this.props.match.params.id}`}  disabled={!access.addCreditNote || ((address.customer?.cust_account_status_id === 3 || address.customer?.cust_account_status_id === 2) ? true : false)}>
                                                                <FALightIcon icon={'box'} style={{color: (address.customer?.cust_account_status_id === 3 || address.customer?.cust_account_status_id === 2) ? 'rgba(0, 0, 0, 0.26)' : '#fff' }}/>
                                                                Add Credit Note
                                                            </Button>
                                                            <Button onClick={this.handleToggleDialog} disabled={!access.addContact}>
                                                                <FALightIcon icon={'users'} style={{color:'#fff'}}/>
                                                                Add Contact
                                                            </Button>
                                                        </ButtonGroup>
                                                        <ButtonGroup fullWidth variant="contained"  color='primary' style={{marginTop: '1em'}}>
                                                            <Button
                                                                fullWidth
                                                                onClick={this.addReminder}
                                                            >
                                                                <AllIcon icon={IconHelper['outstanding-Reminders']} white/>
                                                                Add Reminder</Button>
                                                            <Button
                                                                fullWidth
                                                                variant='contained'
                                                                color={address.address_unsubscribed ? 'primary' : 'secondary'}
                                                                onClick={()=>this.props.deployConfirmation(
                                                                    address.address_unsubscribed ? 
                                                                    'Are you sure you want to resubscribe this address?' :
                                                                    'Are you sure you want to unsubscribe this address?',
                                                                    address.address_unsubscribed ?
                                                                    'Resubscribe Address' :
                                                                    'Unsubscribe Address',
                                                                    this.changeAddressSubscribeStatus
                                                                )}
                                                            >
                                                                <AllIcon icon={IconHelper[address.address_unsubscribed ? 'plus' : 'ban']} white/>
                                                                {address.address_unsubscribed ?
                                                                    'Resubscribe Address' :
                                                                    'Unsubscribe Address'
                                                                }
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Grid>
                                                    
                                                </Grid>
                                            </PaddedPaper>
                                            {address.address_lang && address.address_long &&
                                                <Grid item xs={12} style={{marginTop:'1.5em'}}>
                                                    <PaddedPaper>
                                                        <Map 
                                                            height={400}
                                                            lat={address.address_lang}
                                                            lng={address.address_long}
                                                            center={mapFormData.center}
                                                            marker={<img src={addressSitePin} height={30}/>}
                                                            zoom={15}
                                                            showFilter
                                                            showMarkers
                                                            showStreetView
                                                            showSwapMapType
                                                            allowCreateMarker
                                                            allowDeleteMarker
                                                            allowEditMarker
                                                            onMarkerChange={()=>this.getCustomerAddress(address.address_id)}
                                                        />
                                                        <Grid container style={{marginTop: '1em', alignItems: 'center'}}>
                                                            <Grid item>
                                                                <img src={clenyPin} width={50} style={{padding: '0 5px'}} onClick={()=>{
                                                                    this.setState({mapFormData:{...mapFormData, center: [52.300959, -0.739988]}})
                                                                }} />
                                                            </Grid>
                                                            <Grid item xs={6} style={{paddingLeft: 15}}>
                                                                <Typography>Clenaware | {`${parseFloat(address?.address_nearest_markers?.clenyware?.office?.miles).toFixed(2)} Miles / ${formatSeconds(address?.address_nearest_markers?.clenyware?.office?.travel * 60, true, true, false)} Hours Away`}</Typography>
                                                            </Grid>
                                                            <Grid item style={{marginLeft:'auto'}}>
                                                                {parseFloat(address?.address_nearest_markers?.clenyware?.office?.miles) > 100 ? 'Multiple Charges' : (parseFloat(address?.address_nearest_markers?.clenyware?.office?.miles) * 2 > 100 ? 'Charge Before Return' : 'Round Trip Possible')}
                                                                <AllIcon 
                                                                    size={25}
                                                                    noMargin
                                                                    heavy
                                                                    icon={icons.ev} 
                                                                    color={parseFloat(address?.address_nearest_markers?.clenyware?.office?.miles) > 100 ? colors.red : (parseFloat(address?.address_nearest_markers?.clenyware?.office?.miles) * 2 > 100 ? colors.orange : colors.green)}
                                                                    tooltip={parseFloat(address?.address_nearest_markers?.clenyware?.office?.miles) > 100 ? 'Van will not make journey on charge' : (parseFloat(address?.address_nearest_markers?.clenyware?.office?.miles) * 2 > 100 ? 'Van will require charing before returning' : 'Van can make a round trip')} />            
                                                            </Grid>
                                                        </Grid>
                                                        <MapNoteSection mapCategory='Hotels' formDataName='hotels'/>
                                                        <MapNoteSection mapCategory='Parking' formDataName='parking'/>
                                                        <MapNoteSection mapCategory='Electric Charge Points' formDataName='charging'/>
                                                    </PaddedPaper>
                                                </Grid>
                                            }
                                        </> :
                                        address.address_lang && address.address_long &&
                                            <>
                                                <Grid item xs={12} >
                                                    <PaddedPaper>
                                                        <Map 
                                                            showSwapMapType
                                                            height={400}
                                                            lat={address.address_lang}
                                                            lng={address.address_long}
                                                            controllable
                                                            style={{cursor: 'default !important'}}
                                                        />
                                                    </PaddedPaper>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                            </Grid>
                            
                            <Grid item xs={12} style={{paddingTop: '1.5em'}}>
                                <PaddedPaper >
                                    <Grid container item spacing={3} style={{
                                        justifyContent: 'space-between',
                                    }} >
                                        <Grid item>
                                            <Typography variant="h6" gutterBottom>
                                                Contacts
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataTable  
                                                config={{
                                                    key: 'contact_id',
                                                    pagination: false
                                                }}
                                                columns={[
                                                    {
                                                        heading: 'Name',
                                                        field: rowData => rowData.contact_name
                                                    },
                                                    {
                                                        heading: 'Position',
                                                        field: rowData => rowData.contact_position
                                                    },
                                                    {
                                                        heading: 'Telephone',
                                                        field: rowData => <Dial number={rowData.contact_telephone || '-'} />
                                                    },
                                                    {
                                                        heading: 'Mobile',
                                                        field: rowData => <Dial number={rowData.contact_mobile || '-'} />
                                                    },
                                                    {
                                                        heading: 'Email',
                                                        field: rowData => 
                                                            <>
                                                                {rowData.contact_email}
                                                                {rowData.contact_email && <CopyToClipboard copy={rowData.contact_email} /> }
                                                            </>
                                                    },
                                                    {
                                                        actions: rowData => ([
                                                            {name: 'Update', icon: icons.edit,   link: `/customers/contacts/update/${rowData.contact_id}`, disabled: !access.updateContact ||  !rowData.contact_id},
                                                            {name: 'View',   icon: icons.search, link: `/customers/contacts/view/${rowData.contact_id}`  , disabled: !access.viewContact ||  !rowData.contact_id}
                                                        ])
                                                    }
                                                ]}
                                                rows={_.sortBy(address.active_contacts,[item => item.contact_name.toLowerCase()])}
                                            />
                                        </Grid>
                                    </Grid>
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    }
                    <div className='buttonRow' style={{width: '100%'}}>
                        {currentTab === 'Reminders' && 
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={this.addReminder}
                            >Add Reminder</Button>
                        }
                        <BackButton props={this.props} /></div>
                    <Dialog
                        open={this.state.mapFormData.show}
                        fullWidth={true} 
                        maxWidth="sm" 
                        scroll="body"
                    >
                        <DialogTitle>Update {this.state.mapFormData.label} Notes</DialogTitle>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Textarea
                                        label='Notes'
                                        value={this.state.mapFormData[this.state.mapFormData.show]}
                                        onChange={e => this.setState({mapFormData:{...mapFormData, [this.state.mapFormData.show]: e.target.value}})}
                                    />
                                </Grid>
                                <Grid item xs={12} className='buttonRow'>
                                    <Button variant="outlined" onClick={()=>{this.setState({mapFormData:{...mapFormData, show: false}})}}>Cancel</Button>
                                    <Button variant="contained" color="primary" onClick={this.saveMapNotes}>Save Notes</Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </Grid>

            )
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    deployConfirmation: (message, title, success)     => dispatch(deployConfirmation(message, title, success)),
    deployDialog:       (content, title, size, style=null) => {dispatch(deployDialog(content, title, '', size, style))},
    closeDialog:        ()                            => dispatch(closeDialog())
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ViewCustomerAddress));
