import React, { useState, useEffect } from 'react';
import _                              from 'lodash';

import TabBar      from 'Components/Common/Navigation/TabBar';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';

export default function ({ tabs, defaultTab, nopad, disableMenu, onChange, tab=null }) {
    const [currentTab, setCurrentTab] = useState(defaultTab ? defaultTab : _.filter(tabs, i => i?.label)[0].label);
  
    const handleTabChange = (event, value) => { setCurrentTab(value); onChange && onChange(value); };
  

    const Element = function (props) {
        let _noPad = nopad || _.find(tabs, {label: currentTab})?.noPad;
        return _noPad ? <div {...props} /> : <PaddedPaper {...props} />;
    }

    if (defaultTab) useEffect(() => {setCurrentTab(defaultTab)}, [defaultTab])

    useEffect(() => {
        if(tab) setCurrentTab(tab)
    }, [tab])

    return (
        <>
            <TabBar
                tabs={_.map(_.filter(tabs, i => i?.label), (i, value) => ({label: i.label}))}
                currentTab={currentTab} 
                handleTabChange={handleTabChange}
                disableMenu={_.filter(tabs, i => i?.label).length < 7 ? 1 : disableMenu}
                nested
            />
            <Element key={currentTab} style={{paddingTop: ( nopad ) && '1em'}}>
                {_.map(_.filter(tabs, i => i?.label), (i, value) => {
                    if(i.label === currentTab) {
                        return (i.content)
                    }
                })}
            </Element>
        </>
    )
}