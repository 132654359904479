import { Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import BarChart from 'Components/Common/Charts/BarChart';
import LineChart from 'Components/Common/Charts/LineChart';
import AllIcon from 'Components/Common/Icons/AllIcon';
import LoadingCircle from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper from 'Components/Common/Paper/PaddedPaper';
import { colors } from 'Helpers/ColourHelper';
import IconHelper from 'Helpers/IconHelper';
import { getColorCurrency, getColorPercentageChange, months } from 'Helpers/ReportsHelper';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';


const ConsistentSalesRow = ({icon, data, comparator, showRed, showComparator}) => {
	return (
		<>
			<Grid item xs={showComparator ? 2 : 3} style={{textAlign:'center'}}><AllIcon icon={icon} size='xs' /></Grid>
			<Grid item xs={showComparator ? 4 : 9} style={{color: showRed && colors.red}}>{data < 0 ? '-' : ''}£{parseFloat(Math.abs(data)).toFixed(2)}</Grid>
            {showComparator && <Grid item xs={6}>{comparator}</Grid> }
        </>
	)
}

class SalesReportCompleteMonth extends React.Component {


    render() {
        const {year, month, isLoading, data} = this.props;

        let daysInMonth = moment(
            `${year}-${month}-01`
        ).daysInMonth(),
        currentYear = `${months[parseInt(month)]} ${parseInt(month) > 9 ? year - 1 : year}`;

        let current = false;

        return (
            <Grid item xs={12}>
                <Card style={{color: '#000',backgroundColor: '#FFF',padding: 0}} >
                    <CardHeader
                        avatar={<AllIcon icon={`TextToIcon:${`${month}`.padStart(2,'0')}`} size='medium' style={{color: '#fff'}} noMargin/>}
                        title={
                            <Grid container spacing={1} style={{alignItems: 'flex-end'}}>
                                <Grid item >
                                    <>{months[parseInt(month)]}</>
                                </Grid>
                            </Grid>
                        }
                        titleTypographyProps={{variant:'h6'}}
                        style={{background: 'linear-gradient(90deg, #3D94D6 0%, #2E85C3 100%)', color: '#FFF'}}
                    />
                    <CardContent style={{padding:15}}>
                    {isLoading ? 
                        <Grid item xs={12}>
                            <LoadingCircle/>
                        </Grid>
                    :
                        <Grid container spacing={2}>

                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>{currentYear}</b></Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Grid container>
                                                <Grid item>
                                                    <Typography gutterBottom variant='caption'><b>Total Month</b></Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                {
                                                    _.map([
                                                        {
                                                            icon: IconHelper.products,
                                                            k: 'p'
                                                        },
                                                        {
                                                            icon: IconHelper.services,
                                                            k: 's'
                                                        },
                                                        {
                                                            icon: IconHelper.total,
                                                            k: 't'
                                                        },
                                                    ], ({k, icon}) => 
                                                    
                                                        <ConsistentSalesRow icon={icon} data={data.t.t.m[month][k].b} />
                                                    )
                                                }
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Budget</b></Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{padding: 10}}>
                                            <Typography gutterBottom variant='caption'><b>Budget Month</b></Typography>
                                            <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                {_.map([
                                                    {
                                                        icon: IconHelper.products,
                                                        k: 'p'
                                                    },
                                                    {
                                                        icon: IconHelper.services,
                                                        k: 's'
                                                    },
                                                    {
                                                        icon: IconHelper.total,
                                                        k: 't'
                                                    },
                                                ], ({k, icon}) => 
                                                <ConsistentSalesRow icon={icon} data={data.b.t.m[month][k].b} />
                                            )}
                                            </Grid>
                                        </PaddedPaper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Difference</b></Typography>
                                    </Grid>
                                    {_.map([
                                        {
                                            icon: 'Products',
                                            k: 'p'
                                        },
                                        {
                                            icon: 'Services',
                                            k: 's'
                                        },
                                        {
                                            icon: 'Total',
                                            k: 't'
                                        },
                                    ], ({k, icon}) => 
                                        <Grid item xs={4}>
                                            <PaddedPaper style={{padding: 10}}>
                                                <Typography gutterBottom variant='caption' style={{paddingBottom: 8}}><b>Month {icon}</b></Typography>
                                                <Grid container style={{alignItems: 'center', paddingTop: 4}}>
                                                    <Grid item={3}>
                                                        <AllIcon icon={data.t.t.m[month][k].b > data.b.t.m[month][k].b ? IconHelper.arrowStemUp : (data.t.t.m[month][k].b < data.b.t.m[month][k].b ? IconHelper.arrowStemDown : IconHelper.dash)} size={65} color={
                                                            data.t.t.m[month][k].b > data.b.t.m[month][k].b ? colors.green : (data.t.t.m[month][k].b < data.b.t.m[month][k].b ? colors.red : colors.grey)
                                                        } />
                                                    </Grid>
                                                    <Grid item={9}>
                                                        <Typography variant='body1'>{getColorPercentageChange(data.t.t.m[month][k].b, data.b.t.m[month][k].b, false, false)}</Typography>
                                                        <Typography variant='h5'>{getColorCurrency(data.t.t.m[month][k].b - data.b.t.m[month][k].b , false, false)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </PaddedPaper>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Day On Day</b></Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1" style={{borderBottom: `1px solid ${colors.black}`}}><b>Running Total</b></Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <PaddedPaper style={{padding: 10}}>
                                    <BarChart
                                        data={_.map(_.range(1, (daysInMonth + 1)), idx => {
                                            return {
                                                label: parseInt(idx),
                                                value: {
                                                    [currentYear] : ( data.t.t.m[month].d[idx].f ? parseFloat(data.t.t.m[month].d[idx].f.t.b) : 0),
                                                    'Budget' :   ( data.b.t.m[month].d[idx] ? parseFloat(data.b.t.m[month].d[idx].f.t.b) : 0), 
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    'Budget' :    colors.red,
                                                }
                                            }
                                            
                                        })}
                                        yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                        tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                        style={{
                                            height: 200,
                                        }}
                                    />
                                </PaddedPaper>
                            </Grid>
                            <Grid item xs={6}>
                                <PaddedPaper style={{padding: 10}}>
                                    <LineChart
                                        data={[
                                            {
                                                label:'0',
                                                value: {
                                                    [currentYear] : 0,
                                                    'Budget' :   0,
                                                },
                                                color: {
                                                    [currentYear] : colors.primary,
                                                    'Budget' :    colors.red,
                                                },
                                            },
                                            ..._.map(_.range(1, (daysInMonth + 1)), idx => {
                                                return {
                                                    label: parseInt(idx),
                                                    value: {
                                                        [currentYear] : ( data.t.t.m[month].d[idx] ? parseFloat(data.t.t.m[month].d[idx].r.t.b) : 0),
                                                        'Budget' :  parseFloat(data.b.t.m[month].d[idx].r.t.b), 
                                                    },
                                                    color: {
                                                        [currentYear] : colors.primary,
                                                        'Budget' :    colors.red,
                                                    },
                                                }
                                            
                                            })
                                        ]}
                                        yLabel={i => `${i<0?'-':''}£${Math.abs(i)}`}
                                        tLabel={(e)=>`${parseFloat(e.raw)<0?'-':''}£${parseFloat(Math.abs(e.raw)).toFixed(2)}`}
                                        style={{
                                            height: 200,
                                        }}
                                        noPoints
                                    />
                                </PaddedPaper>
                            </Grid>
                        </Grid>
                    }
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default SalesReportCompleteMonth;