import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import AllIcon          from 'Components/Common/Icons/AllIcon';
import LoadingCircle    from 'Components/Common/LoadingCircle/LoadingCircle';
import PaddedPaper      from 'Components/Common/Paper/PaddedPaper';
import PDFViewer        from 'Components/Common/PDFViewer/PDFViewer';
import BackButton       from 'Components/Common/Buttons/BackButton';
import StickyNotes      from 'Components/Common/StickyNotes/StickyNotes';
import StickyBox        from 'Components/Common/Containers/StickyBox';
import WorksOrderReport from 'Components/Reports/WorksOrderReports/WorksOrderReport'
import DownloadCSV      from 'Components/Common/Buttons/DownloadCSV';
import TabBar           from 'Components/Common/Navigation/TabBar';

import BuildAdditionals    from './sections/BuildAdditionals';
import BuildReturns        from './sections/BuildReturns';
import BuildWriteOffs      from './sections/BuildWriteOffs';
import Feedback            from './sections/Feedback';
import FirstOffChecks      from './sections/FirstOffChecks';
import Logs                from './sections/Logs';
import Overview            from './sections/Overview';
import Picks               from './sections/Picks';
import TimeSheet           from './sections/TimeSheet';
import ImportantInfoDialog from './sections/ImportantInfoDialog';
import Shortages           from './sections/Shortages';
import StageIndicater      from './sections/StageIndicater';
import ReviewProgress      from './sections/ReviewProgress';
import WorksOrderNotes     from './sections/WorksOrderNotes';
import Review              from './sections/Review';

import { colors }        from 'Helpers/ColourHelper';
import icons             from 'Helpers/IconHelper';
import { pdfFromBase64 } from "Helpers/PDFHelper";

import { isTablet }       from 'Functions/MiscFunctions';
import { downloadS3File } from 'Functions/MiscFunctions';

import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';

import { Grid, Typography, Button } from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

const initialState = {
    isLoading:          true, 
    worksOrder:         {}, 
    subAssembly:        {},
    access:             {},  
    importantNoteShown: null,
    tab:                'Overview',
    //tab:                'Report',
    reviewMode:         false,
    MasterExcelData:    null,
}

class ViewWorksOrder extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount(){
        this.getAccess();
        this.getWorksOrder();
    }

    getAccess = () => {
        let access = ['works-orders:review', 'works-orders-review:check-all'];
        Promise.all(_.map(access, i => API.get(`/staff/my/access/check/${i}`)))
        .then(response => {
            const ret = {};
            access.forEach((item, idx) => { ret[item] = response[idx].data.has_access; })
            this.setState({access: ret});
        });
    }

    getWorksOrder = () => {
        API.get(`/worksOrders/${this.props.match.params.woId}`)
        .then(res => {
            this.setState({
                isLoading:          false,
                worksOrder:         res.data,
                currentTab:         this.state.currentTab ? this.state.currentTab : ( res.data.wo_status === 'Build' && res.data.shortage?.woss_complete ? 'Build' : 'Overview' ),
            }, () => {
                if ( this.state.worksOrder.wo_status === 'Completed' ){
                    this.masterExcelData()
                }
            })
            if (!this.state.importantNoteShown && res.data.wo_important_note) this.displayImportantInfo();
            else                                                              this.setState({importantNoteShown: true});
        });
    }

    displayImportantInfo = () => {
        this.setState({importantNoteShown: true}, () => {
            this.props.deployDialog(
            <>
                <Typography component="p">
                    {this.state.worksOrder?.wo_important_note && this.state.worksOrder?.wo_important_note.split('\n').map((item, key) => {return <span key={key}>{item}<br/></span>})}
                </Typography>
                <Button variant='outlined' style={{float: 'right'}} onClick={this.props.closeDialog}>Close</Button>
            </>
            , 'Important Information', null, 'sm', {backgroundColor : '#fcc6c5', color: '#000'})
        })
    }
    
    pickReport = () => {
        API.get(`/worksOrders/${this.props.match.params.woId}/reports`, {params: {type: 'pick'}})
        .then(res => {
            const Element = <PDFViewer 
                                pdfData={res.data.pdf}
                                onClose={() => this.props.closeDialog()}
                                downloadTitle={res.data.pdfName}
                            />

            this.props.deployDialog(Element, "", null, 'fs')
        })
    }

    handleTabChange = tab => {
        this.setState({tab})
    }

    handleReview = () => {
        this.setState({reviewMode: !this.state.reviewMode})
    }

    downloadFeedback = () => {
        API.get(`/worksOrders/${this.props.match.params.woId}/feedback/download`)
        .then(result => {
            pdfFromBase64(result.data.pdf, result.data.pdfName);
        });
    }

    masterExcelData = () => {
        API.get(`/worksOrders/${this.props.match.params.woId}/masterExcel`)
        .then(res => {
            this.setState({MasterExcelData: res.data});
        })
    }

    DownloadPD = (pdf) => () => {
        downloadS3File(pdf);
    }

    ViewPD = (url) => () =>{
        this.props.deployDialog(<PDFViewer 
            pdfUrl={url}
            onClose={() => this.props.closeDialog()}
            downloadTitle={'process_document.pdf'}
        />, "", null, 'md');
    }

    downloadWorksOrderHeader = () => {
        API.get(`/worksOrders/${this.props.match.params.woId}/headerSheet`)
        .then(result => {
            if(result.data) {
                pdfFromBase64(result.data.pdf, result.data.pdfName);
            }
        })
    }

    viewWorksOrderHeader = () => {
        API.get(`/worksOrders/${this.props.match.params.woId}/headerSheet`)
        .then(result => {
            if(result.data) {
                this.props.deployDialog(<PDFViewer 
                    pdfData={result.data.pdf}
                    onClose={() => this.props.closeDialog()}
                />, "", null, 'md');
            }
        })
    }

    render(){

        const { isLoading, worksOrder, tab, reviewMode, access, MasterExcelData } = this.state;
        const { classes } = this.props;

        if (isLoading) return (<LoadingCircle />);        

        const tabs = _.filter([
            {
                label:   'Overview',
                content: <Overview worksOrder={worksOrder} refresh={this.getWorksOrder}/>
            },
            worksOrder.pick && {
                label:   'Picks',
                content: <Picks worksOrder={worksOrder}/>
            },
            worksOrder.shortage && {
                label:   'Shortages',
                content: <Shortages worksOrder={worksOrder}/>
            },
            worksOrder.write_offs?.length > 0 && {
                label:   'Write Offs',
                content: <BuildWriteOffs worksOrder={worksOrder} refresh={this.getWorksOrder} reviewMode={reviewMode} />
            },
            worksOrder.additionals?.length > 0 && {
                label:   'Additional Stock',
                content: <BuildAdditionals worksOrder={worksOrder}/>
            },
            worksOrder.returns?.length > 0 && {
                label:   'Returned Stock',
                content: <BuildReturns worksOrder={worksOrder}/>
            },
            _.filter(worksOrder?.build?.stages, i => i.wobs_first_pass || i.declined.length > 0).length > 0 && {
                label:   'First Off Checks',
                content: <FirstOffChecks worksOrder={worksOrder}/>
            },
            worksOrder.build?.time_sheets?.length  > 0  && {
                label:   'Build Time Sheet',
                content: <TimeSheet worksOrder={worksOrder}/>
            },
            worksOrder.build && {
                label:   'Feedback',
                content: <Feedback worksOrder={worksOrder} refresh={this.getWorksOrder} reviewMode={reviewMode} />
            },
            worksOrder.notes?.length > 0  && {
                label:   'Notes',
                content: <WorksOrderNotes worksOrder={worksOrder} />
            },
            worksOrder.logs?.length > 0  && {
                label:   'Activity Logs',
                content: <Logs worksOrder={worksOrder} />
            },
            worksOrder?.build?.stages.length > 0 && {
                label:   'Report',
                content: <WorksOrderReport worksOrder={worksOrder} />
            },
            worksOrder.review && !reviewMode && {
                label:   'Review',
                content: <Review worksOrder={worksOrder} />
            },
        ], i=> i?.label);

        const handleNextTab = () => {
            const idx = _.findIndex(tabs, i => i.label === tab); 
            this.setState({tab: tabs[(idx < tabs.length - 1) ? idx + 1 : 0].label});
        }

        const handleToTab = tab => {
            this.setState({tab});
        }
        
        if (worksOrder.wo_lock && this.props.loggedInStaff.id !== worksOrder.wo_lock) {
            return (
                <Grid container>
                    <Grid item xs={12} style={{paddingBottom: '1.5em'}}>
                        <Typography variant="h5">
                            Works Order
                        </Typography>
                    </Grid>
                    {!!(parseInt(worksOrder.wo_version) > 0 && (worksOrder.wo_status !== 'Completed' && worksOrder.wo_status !== 'Cancelled') ) &&
                        !!(worksOrder.wo_version !== worksOrder.part.part_issue_status_pdf) &&
                        <Grid item xs={12} style={{paddingBottom: '1.5em'}}>
                            <PaddedPaper style={{backgroundColor: colors.importantInfo, padding: 10}}>
                                <Grid container style={{width: '100%', alignItems: 'center'}}>
                                    <Grid item style={{marginLeft: 15}}>
                                        <AllIcon icon={icons.versionMissMatch} size={25} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h6'>This Works Order was created with an older version. Created: v{parseInt(worksOrder.wo_version)} / Current: v{parseInt(worksOrder.part.part_issue_status_pdf)}</Typography>
                                    </Grid>
                                    <Grid item style={{marginLeft: 'auto', marginRight: 15}}>
                                        <AllIcon icon={icons.versionMissMatch} size={25} noMargin/>
                                    </Grid>
                                </Grid>
                            </PaddedPaper>
                        </Grid>
                    }
                    <Grid item style={{paddingBottom: '1.5em', width: isTablet() ? '100%' : '80%' }}>
                        <PaddedPaper>
                            <Grid container spacing={3} style={{justifyContent:'center', margin: '1.3em 0', alignItems: 'top'}}>
                                <Grid item style={{textAlign: 'center'}}>
                                    <AllIcon 
                                        size='small' 
                                        icon={icons.plus} 
                                        className={classes.stepLike} 
                                        color={colors.white} 
                                        style={{backgroundColor:    colors.green}}
                                    /><br/>
                                    Created
                                </Grid>
                                <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                    <hr/>
                                </Grid>
                                <Grid item style={{textAlign: 'center'}}>
                                    <AllIcon 
                                        size='small' 
                                        icon={icons.picking} 
                                        className={classes.stepLike} 
                                        color={colors.white} 
                                        style={{backgroundColor:  worksOrder?.pick?.wop_status === 'Completed' ? colors.green  : colors.orange}}
                                    /><br/>
                                    Picking
                                </Grid>
                                {worksOrder.shortage && [
                                    <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                        <hr/>
                                    </Grid>,
                                    <Grid item style={{textAlign: 'center'}}>
                                        <AllIcon 
                                            size='small' 
                                            icon={ icons.picking} 
                                            className={classes.stepLike} 
                                            color={colors.white} 
                                            style={{backgroundColor: worksOrder.shortage ? (worksOrder.shortage.woss_complete  || worksOrder.wo_status == 'Completed' ? colors.green : colors.orange ) : colors.red}}
                                        /><br/>
                                        Shortages
                                    </Grid>
                                ]}
                                <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                    <hr/>
                                </Grid>
                                {worksOrder.wo_status === 'Cancelled' ?
                                    <>
                                        <Grid item style={{textAlign: 'center'}}>
                                            <AllIcon 
                                                size='small' 
                                                icon={icons.cancel} 
                                                className={classes.stepLike} 
                                                color={colors.white} 
                                                style={{backgroundColor: colors.red}}
                                            /><br/>
                                            Cancelled
                                        </Grid>
                                    </>
                                    : 
                                    <>
                                        <Grid item style={{textAlign: 'center'}}>
                                            <AllIcon 
                                                size='small' 
                                                icon={icons.worksOrder} 
                                                className={classes.stepLike} 
                                                color={colors.white} 
                                                style={{backgroundColor: ( worksOrder.build ? ( worksOrder.build.current_stage ? ( (!worksOrder?.shortage || worksOrder?.shortage?.woss_complete ) ? colors.orange : colors.red) : colors.green) : colors.red )}}
                                            /><br/>
                                            Build
                                        </Grid>
                                        <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                            <hr/>
                                        </Grid>
                                        <Grid item style={{textAlign: 'center'}}>
                                            <AllIcon 
                                                size='small' 
                                                icon={icons.review} 
                                                className={classes.stepLike} 
                                                color={colors.white} 
                                                style={{backgroundColor:  worksOrder.wo_completed ? colors.green : (worksOrder.wo_status == 'Review' ? colors.orange : colors.red ) }}
                                            /><br/>
                                            Review
                                        </Grid>
                                        <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                            <hr/>
                                        </Grid>
                                        <Grid item style={{textAlign: 'center'}}>
                                            <AllIcon 
                                                size='small' 
                                                icon={icons.tick} 
                                                className={classes.stepLike} 
                                                color={colors.white} 
                                                style={{backgroundColor:  worksOrder.wo_completed ? colors.green : colors.red}}
                                            /><br/>
                                            Completed
                                        </Grid>
                                    </>
                                }   
                                
                            </Grid>

                            <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                                {worksOrder.wo_ref}
                            </Typography>
                            <Grid container spacing={2} style={{justifyContent: 'center', marginBottom: '1em'}}>
                                {!!(worksOrder.wo_status === 'Completed') ?
                                    <Grid item xs={12}>
                                        {
                                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign} style={{fontWeight: 'bold'}}>
                                                        Process Document v{worksOrder.wo_version} (Created)
                                                        <Button variant="outlined"
                                                            color="default"
                                                            size="small"
                                                            className={classes.btn}
                                                            onClick={this.DownloadPD(worksOrder.part.pdf_drawing.url)}
                                                        >
                                                            <AllIcon icon='download' />
                                                            Download
                                                        </Button> 
                                                        <Button variant="outlined"
                                                            color="default"
                                                            size="small"
                                                            className={classes.btn}
                                                            onClick={this.ViewPD(worksOrder.part.pdf_drawing.url)}
                                                        >
                                                            <AllIcon icon={icons.search} />
                                                            View
                                                        </Button> 
                                                    </Typography>
                                        }
                                        
                                        <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                            Process Document{` v${worksOrder.wo_version_completed}`} (Completed)
                                            <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={this.DownloadPD(worksOrder.part.pdf_drawing_completed.url)}
                                            >
                                                <AllIcon icon='download' />
                                                Download
                                            </Button> 
                                            <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={this.ViewPD(worksOrder.part.pdf_drawing_completed.url)}
                                            >
                                                <AllIcon icon={icons.search} />
                                                View
                                            </Button> 
                                        </Typography>
                                    </Grid>
                                    :
                                    <Grid item xs={12}>
                                        {
                                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign} style={{fontWeight: 'bold'}}>
                                                        Process Document v{worksOrder.wo_version} (Created)
                                                        <Button variant="outlined"
                                                            color="default"
                                                            size="small"
                                                            className={classes.btn}
                                                            onClick={this.DownloadPD(worksOrder.part.pdf_drawing.url)}
                                                        >
                                                            <AllIcon icon='download' />
                                                            Download
                                                        </Button> 
                                                        <Button variant="outlined"
                                                            color="default"
                                                            size="small"
                                                            className={classes.btn}
                                                            onClick={this.ViewPD(worksOrder.part.pdf_drawing.url)}
                                                        >
                                                            <AllIcon icon={icons.search} />
                                                            View
                                                        </Button> 
                                                    </Typography>
                                        }
                                        
                                        <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                            Process Document{` v${worksOrder.part.part_issue_status_pdf}`} (Current)
                                            <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={this.DownloadPD(worksOrder.part.pdf_drawing_current.url)}
                                            >
                                                <AllIcon icon='download' />
                                                Download
                                            </Button> 
                                            <Button variant="outlined"
                                                color="default"
                                                size="small"
                                                className={classes.btn}
                                                onClick={this.ViewPD(worksOrder.part.pdf_drawing_current.url)}
                                            >
                                                <AllIcon icon={icons.search} />
                                                View
                                            </Button> 
                                        </Typography>
                                </Grid>
                            }
                                <Grid item xs={12}>
                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                        Header Sheet
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={this.downloadWorksOrderHeader}
                                        >
                                            <AllIcon icon='download' />
                                            Download
                                        </Button>
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={this.viewWorksOrderHeader}
                                        >
                                            <AllIcon icon={icons.search} />
                                            View
                                        </Button>  
                                    </Typography>
                                </Grid>
                            </Grid>
                            <br className='xs-only' /> 
                            <hr/>
                            <Grid item xs={12} style={{textAlign:'center', paddingTop: '1em'}}>
                                <AllIcon icon={icons.lock} size={106} />
                                <Typography variant="h4" style={{textAlign: 'center', paddingTop: '1em'}}>
                                    Works Order is locked by another user
                                </Typography>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                    <Grid item style={{paddingLeft: isTablet() ? 0 : '1.5em', width: isTablet() ? '100%' : '20%'}}>
                    <StickyBox >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <StageIndicater worksOrder={worksOrder} /> 
                            </Grid>
                            {reviewMode ?
                                <Grid item xs={12}>
                                    <ReviewProgress 
                                        worksOrder={worksOrder} tab={tab} 
                                        access={access} 
                                        onComplete={()=>{this.handleReview(); this.getWorksOrder() }}
                                        getWorksOrder={this.getWorksOrder}
                                        nextTab={() => { handleNextTab(); this.props.scrollToTop();}}
                                        toTab={tab => { handleToTab(tab); this.props.scrollToTop();}}
                                        close={()=>this.setState({reviewMode: false})}
                                    />
                                </Grid> :
                                [
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{backgroundColor: colors.importantNote, color: colors.black}}>
                                            <Grid container style={{alignItems: 'center'}}>
                                                <Grid item xs={8}>
                                                    <Typography variant='h5' style={{margin: 0}}>Important Info </Typography>
                                                </Grid>
                                                <Grid item style={{marginLeft: 'auto'}}>
                                                    <AllIcon noMargin icon={worksOrder.wo_important_note ? icons.edit : icons.plus} color={colors.black}  onClick={()=>this.props.deployDialog(<ImportantInfoDialog worksOrder={worksOrder} refresh={this.getWorksOrder}/>, 'Important Information', null, 'md')} />
                                                </Grid>
                                            </Grid>
                                            {worksOrder.wo_important_note &&
                                                <Typography variant='body1' style={{margin: 0, marginTop: 10, }}>{worksOrder.wo_important_note.split('\n').map((item, key) => <>{item}<br/></>)}</Typography> 
                                            }
                                        </PaddedPaper>
                                    </Grid>,
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{backgroundColor: colors.stickyNotes}} >
                                            <Typography variant="h5"> Sticky Notes </Typography>
                                            <StickyNotes edit={1} woId={worksOrder.wo_id} placeholder='Start Typing...'/>
                                        </PaddedPaper>
                                    </Grid>
                                ]
                            }
                        </Grid>
                    </StickyBox>
                </Grid>
                <Grid container item style={{justifyContent: 'flex-end', width: isTablet() ? '100%': '80%'}} spacing={2}>
                        <Grid item xs={12} className='buttonRow'>
                            <BackButton />
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        
        return (
            <Grid container>
                <Grid item xs={12} style={{paddingBottom: '1.5em'}}>
                    <Typography variant="h5">
                        Works Order
                    </Typography>
                </Grid>
                {!!(parseInt(worksOrder.wo_version) > 0 && (worksOrder.wo_status !== 'Completed' && worksOrder.wo_status !== 'Cancelled') ) &&
                    !!(worksOrder.wo_version !== worksOrder.part.part_issue_status_pdf) &&
                    <Grid item xs={12} style={{paddingBottom: '1.5em'}}>
                        <PaddedPaper style={{backgroundColor: colors.importantInfo, padding: 10}}>
                            <Grid container style={{width: '100%', alignItems: 'center'}}>
                                <Grid item style={{marginLeft: 15}}>
                                    <AllIcon icon={icons.versionMissMatch} size={25} />
                                </Grid>
                                <Grid item>
                                    <Typography variant='h6'>This Works Order was created with an older version. Created: v{parseInt(worksOrder.wo_version)} / Current: v{parseInt(worksOrder.part.part_issue_status_pdf)}</Typography>
                                </Grid>
                                <Grid item style={{marginLeft: 'auto', marginRight: 15}}>
                                    <AllIcon icon={icons.versionMissMatch} size={25} noMargin/>
                                </Grid>
                            </Grid>
                        </PaddedPaper>
                    </Grid>
                }
                <Grid item style={{paddingBottom: '1.5em', width: isTablet() ? '100%' : '80%' }}>
                    <PaddedPaper>
                        <Grid container spacing={3} style={{justifyContent:'center', margin: '1.3em 0', alignItems: 'top'}}>
                            <Grid item style={{textAlign: 'center'}}>
                                <AllIcon 
                                    size='small' 
                                    icon={icons.plus} 
                                    className={classes.stepLike} 
                                    color={colors.white} 
                                    style={{backgroundColor:    colors.green}}
                                /><br/>
                                Created
                            </Grid>
                            <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                <hr/>
                            </Grid>
                            <Grid item style={{textAlign: 'center'}}>
                                <AllIcon 
                                    size='small' 
                                    icon={icons.picking} 
                                    className={classes.stepLike} 
                                    color={colors.white} 
                                    style={{backgroundColor:  worksOrder?.pick?.wop_status === 'Completed' ? colors.green  : colors.orange}}
                                /><br/>
                                Picking
                            </Grid>
                            {worksOrder.shortage && [
                                <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                    <hr/>
                                </Grid>,
                                <Grid item style={{textAlign: 'center'}}>
                                    <AllIcon 
                                        size='small' 
                                        icon={ icons.picking} 
                                        className={classes.stepLike} 
                                        color={colors.white} 
                                        style={{backgroundColor: worksOrder.shortage ? (worksOrder.shortage.woss_complete  || worksOrder.wo_status == 'Completed' ? colors.green : colors.orange ) : colors.red}}
                                    /><br/>
                                    Shortages
                                </Grid>
                            ]}
                            <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                <hr/>
                            </Grid>
                            {worksOrder.wo_status === 'Cancelled' ?
                                <>
                                    <Grid item style={{textAlign: 'center'}}>
                                        <AllIcon 
                                            size='small' 
                                            icon={icons.cancel} 
                                            className={classes.stepLike} 
                                            color={colors.white} 
                                            style={{backgroundColor: colors.red}}
                                        /><br/>
                                        Cancelled
                                    </Grid>
                                </>
                                : 
                                <>
                                    <Grid item style={{textAlign: 'center'}}>
                                        <AllIcon 
                                            size='small' 
                                            icon={icons.worksOrder} 
                                            className={classes.stepLike} 
                                            color={colors.white} 
                                            style={{backgroundColor: ( worksOrder.build ? ( worksOrder.build.current_stage ? ( (!worksOrder?.shortage || worksOrder?.shortage?.woss_complete ) ? colors.orange : colors.red) : colors.green) : colors.red )}}
                                        /><br/>
                                        Build
                                    </Grid>
                                    <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                        <hr/>
                                    </Grid>
                                    <Grid item style={{textAlign: 'center'}}>
                                        <AllIcon 
                                            size='small' 
                                            icon={icons.review} 
                                            className={classes.stepLike} 
                                            color={colors.white} 
                                            style={{backgroundColor:  worksOrder.wo_completed ? colors.green : (worksOrder.wo_status == 'Review' ? colors.orange : colors.red ) }}
                                        /><br/>
                                        Review
                                    </Grid>
                                    <Grid item style={{textAlign: 'center', width: '5%', marginTop: 6}}>
                                        <hr/>
                                    </Grid>
                                    <Grid item style={{textAlign: 'center'}}>
                                        <AllIcon 
                                            size='small' 
                                            icon={icons.tick} 
                                            className={classes.stepLike} 
                                            color={colors.white} 
                                            style={{backgroundColor:  worksOrder.wo_completed ? colors.green : colors.red}}
                                        /><br/>
                                        Completed
                                    </Grid>
                                </>
                            }   
                            
                        </Grid>

                        <Typography variant="h4" align="center" className={classes.heading} style={{marginBottom: 25}}>
                            {worksOrder.wo_ref}
                        </Typography>
                        <Grid container spacing={2} style={{justifyContent: 'center', marginBottom: '1em'}}>
                            {!!(worksOrder.wo_status === 'Completed') ?
                                <Grid item xs={12}>
                                    {
                                                <Typography variant="body1" align="center" gutterBottom className={classes.poAlign} style={{fontWeight: 'bold'}}>
                                                    Process Document v{worksOrder.wo_version} (Created)
                                                    <Button variant="outlined"
                                                        color="default"
                                                        size="small"
                                                        className={classes.btn}
                                                        onClick={this.DownloadPD(worksOrder.part.pdf_drawing.url)}
                                                    >
                                                        <AllIcon icon='download' />
                                                        Download
                                                    </Button> 
                                                    <Button variant="outlined"
                                                        color="default"
                                                        size="small"
                                                        className={classes.btn}
                                                        onClick={this.ViewPD(worksOrder.part.pdf_drawing.url)}
                                                    >
                                                        <AllIcon icon={icons.search} />
                                                        View
                                                    </Button> 
                                                </Typography>
                                    }
                                    
                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                        Process Document{` v${worksOrder.wo_version_completed}`} (Completed)
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={this.DownloadPD(worksOrder.part.pdf_drawing_completed.url)}
                                        >
                                            <AllIcon icon='download' />
                                            Download
                                        </Button> 
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={this.ViewPD(worksOrder.part.pdf_drawing_completed.url)}
                                        >
                                            <AllIcon icon={icons.search} />
                                            View
                                        </Button> 
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    {
                                                <Typography variant="body1" align="center" gutterBottom className={classes.poAlign} style={{fontWeight: 'bold'}}>
                                                    Process Document v{worksOrder.wo_version} (Created)
                                                    <Button variant="outlined"
                                                        color="default"
                                                        size="small"
                                                        className={classes.btn}
                                                        onClick={this.DownloadPD(worksOrder.part.pdf_drawing.url)}
                                                    >
                                                        <AllIcon icon='download' />
                                                        Download
                                                    </Button> 
                                                    <Button variant="outlined"
                                                        color="default"
                                                        size="small"
                                                        className={classes.btn}
                                                        onClick={this.ViewPD(worksOrder.part.pdf_drawing.url)}
                                                    >
                                                        <AllIcon icon={icons.search} />
                                                        View
                                                    </Button> 
                                                </Typography>
                                    }
                                    
                                    <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                        Process Document{` v${worksOrder.part.part_issue_status_pdf}`} (Current)
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={this.DownloadPD(worksOrder.part.pdf_drawing_current.url)}
                                        >
                                            <AllIcon icon='download' />
                                            Download
                                        </Button> 
                                        <Button variant="outlined"
                                            color="default"
                                            size="small"
                                            className={classes.btn}
                                            onClick={this.ViewPD(worksOrder.part.pdf_drawing_current.url)}
                                        >
                                            <AllIcon icon={icons.search} />
                                            View
                                        </Button> 
                                    </Typography>
                            </Grid>
                        }
                            <Grid item xs={12}>
                                <Typography variant="body1" align="center" gutterBottom className={classes.poAlign}>
                                    Header Sheet
                                    <Button variant="outlined"
                                        color="default"
                                        size="small"
                                        className={classes.btn}
                                        onClick={this.downloadWorksOrderHeader}
                                    >
                                        <AllIcon icon='download' />
                                        Download
                                    </Button>
                                    <Button variant="outlined"
                                        color="default"
                                        size="small"
                                        className={classes.btn}
                                        onClick={this.viewWorksOrderHeader}
                                    >
                                        <AllIcon icon={icons.search} />
                                        View
                                    </Button>  
                                </Typography>
                            </Grid>
                        </Grid>
                        <br className='xs-only' /> 
                        <TabBar
                            tabs={_.map(_.filter(tabs, i => i?.label), (i, value) => ({label: i.label}))}
                            currentTab={tab} 
                            handleTabChange={(e,v) => this.handleTabChange(v)}
                            nested
                            style={{marginBottom: '1em'}}
                        />
                        <>
                            {_.find(tabs, {label: tab})?.content}
                        </>
                    </PaddedPaper>
                </Grid>
                <Grid item style={{paddingLeft: isTablet() ? 0 : '1.5em', width: isTablet() ? '100%' : '20%'}}>
                    <StickyBox >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <StageIndicater worksOrder={worksOrder} /> 
                            </Grid>
                            {reviewMode ?
                                <Grid item xs={12}>
                                    <ReviewProgress 
                                        worksOrder={worksOrder} tab={tab} 
                                        access={access} 
                                        onComplete={()=>{this.handleReview(); this.getWorksOrder() }}
                                        getWorksOrder={this.getWorksOrder}
                                        nextTab={() => { handleNextTab(); this.props.scrollToTop();}}
                                        toTab={tab => { handleToTab(tab); this.props.scrollToTop();}}
                                        close={()=>this.setState({reviewMode: false})}
                                    />
                                </Grid> :
                                [
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{backgroundColor: colors.importantNote, color: colors.black}}>
                                            <Grid container style={{alignItems: 'center'}}>
                                                <Grid item xs={8}>
                                                    <Typography variant='h5' style={{margin: 0}}>Important Info </Typography>
                                                </Grid>
                                                <Grid item style={{marginLeft: 'auto'}}>
                                                    <AllIcon noMargin icon={worksOrder.wo_important_note ? icons.edit : icons.plus} color={colors.black}  onClick={()=>this.props.deployDialog(<ImportantInfoDialog worksOrder={worksOrder} refresh={this.getWorksOrder}/>, 'Important Information', null, 'md')} />
                                                </Grid>
                                            </Grid>
                                            {worksOrder.wo_important_note &&
                                                <Typography variant='body1' style={{margin: 0, marginTop: 10, }}>{worksOrder.wo_important_note.split('\n').map((item, key) => <>{item}<br/></>)}</Typography> 
                                            }
                                        </PaddedPaper>
                                    </Grid>,
                                    <Grid item xs={12}>
                                        <PaddedPaper style={{backgroundColor: colors.stickyNotes}} >
                                            <Typography variant="h5"> Sticky Notes </Typography>
                                            <StickyNotes edit={1} woId={worksOrder.wo_id} placeholder='Start Typing...'/>
                                        </PaddedPaper>
                                    </Grid>
                                ]
                            }
                        </Grid>
                    </StickyBox>
                </Grid>
                <Grid container item style={{justifyContent: 'flex-end', width: isTablet() ? '100%': '80%'}} spacing={2}>
                    <Grid item xs={12} className='buttonRow'>
                        { worksOrder.wo_status === 'Picking' && tab === 'Picks' &&
                            <Button variant='contained' color='primary' onClick={()=>this.props.history.push(`/WorksOrders/pick/${worksOrder.wo_id}`)}><AllIcon color={colors.white} icon={icons.qr}/>Pick Works Orders</Button>
                        }
                        { worksOrder.wo_status == 'Review' && access['works-orders:review'] && !reviewMode &&
                            <Button variant='contained' color='primary' onClick={this.handleReview}>Review</Button>
                        }
                        { ( worksOrder.wo_status == 'Build' && tab === 'Shortages' && ( worksOrder.shortage && !worksOrder.shortage?.woss_complete) ) &&  
                            <Button variant='contained' color='primary' onClick={()=>this.props.history.push(`/WorksOrders/shortages/${worksOrder.wo_id}`)}><AllIcon color={colors.white} icon={icons.qr }/>Pick Shortages</Button>
                        }
                        {!!reviewMode && tab === 'Feedback' && worksOrder.feedback.length > 0 && <Button variant='contained' color='primary' onClick={this.downloadFeedback}>Feedback Report</Button>}
                        { ( worksOrder.wo_status == 'Completed' && tab === 'Report' && MasterExcelData ) &&
                             <DownloadCSV 
                                 data    ={MasterExcelData} color='primary' variant='contained' 
                                 filename={`MasterExcelParts_${worksOrder.wo_ref}.csv`} 
                                 disabled={isLoading}
                             >download CSV</DownloadCSV>
                         }
                        <BackButton />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const styles = theme => ({
    btn: {
        marginLeft: theme.spacing(1),
    },
    btnRow: {
        textAlign: 'center', 
    },
    btnRowBtn: {   
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
    [theme.breakpoints.down('xs')]: {
        heading: {
            fontSize: '1.6em',
        },
        stepper: {
            display: 'none',
        },
    },
    statusHeading: {
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        color: 'black'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    tabScrollButtons: {
        color: 'black'
    },
    stepLike: {
        padding:            '0.5em',
        borderRadius:       '2em',
        textAlign:          'center',
        marginBottom:       '0.5em',
    },
    sticky: {
        position: 'sticky',
        top:      62,
        zIndex:   500
    }
});


const mapStateToProps = state => ({
    statePersistence: state.statePersistence,
    loggedInStaff:    state.staffAuth.staff,
})

const mapDispatchToProps = dispatch => ({
    deployDialog:       (content, title, variant, size, style=null) => dispatch(deployDialog(content, title, variant, size, style)),
    closeDialog:        ()                                          => dispatch(closeDialog()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewWorksOrder));