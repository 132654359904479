import * as dialogActionTypes from 'Actions/Dialog/Types/Types';

export const deployDialog = (content, title, variant = 'standard', size = 'md', style = {}) => {
    return dispatch => {
        dispatch({type: dialogActionTypes.DIALOG_OPEN, content, title, variant, size, style});
    }
}

export const closeDialog = () => {
    return dispatch => {
        dispatch({type: dialogActionTypes.DIALOG_CLOSE});
    }
}