import React, { Component } from 'react';
import API                  from 'API';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { colors }            from 'Helpers/ColourHelper';
import icons                 from 'Helpers/IconHelper';
import { pdfDataFromBase64 } from 'Helpers/PDFHelper';

import DataTable            from 'Components/Common/DataTables/CiDataTable';
import { FullScreenDialog } from 'Components/Common/Dialogs/FullScreenDialog';
import AllIcon              from 'Components/Common/Icons/AllIcon';
import PDFViewer            from 'Components/Common/PDFViewer/PDFViewer';

import Addition           from './Addition';
import FirstOffCheck      from './FirstOffCheck';
import Return             from './Return';
import WriteOff           from './WriteOff';
import GlobalWriteOff     from './GlobalWriteOff';
import WriteOffScanDialog from './WriteOffScanDialog';

import { deployConfirmation }        from 'Actions/Confirmation/Confirmation';
import { closeDialog, deployDialog } from 'Actions/Dialog/Dialog';
import { deploySnackBar }            from 'Actions/SnackBar/SnackBar';
import { printLabel }                from 'Actions/Print/Print';

const initialState = {
    isLoading: false,
    stage:     {},
}

class Drawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialState,
            stage: this.props.stage,
        };
    }

    handleClockIn =  (showDoc=true) => { 
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/clockIn`)
        .then(res => {
            if (res.data.errors) {
                this.props.deploySnackBar('Works Order in review','warning');
                this.props.refresh();
            } else {
                if (showDoc) {
                    if (this.props.worksOrder.wo_version && (this.props.worksOrder.wo_version !== this.props.worksOrder.part.part_issue_status_pdf)) {
                        this.props.deployDialog(
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Button
                                        variant='outlined'
                                        onClick={this.showProcessDocument(this.props.worksOrder.part.pdf_drawing.url)}
                                        fullWidth
                                        style={{background: colors.orange, color: colors.white}}
                                    >Created (v{this.props.worksOrder.wo_version})</Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant='outlined'
                                        onClick={this.showProcessDocument(this.props.worksOrder.part.pdf_drawing_current.url)}
                                        fullWidth
                                    >Current (v{this.props.worksOrder.part.part_issue_status_pdf})</Button>
                                </Grid>
                            </Grid>, 
                            'Select Document Version', 
                            null, 
                            'sm'
                        )
                    } else {
                        this.showProcessDocument(this.props.worksOrder.part.pdf_drawing_current.url)()
                    }
                }
                this.props.refresh();
            }
        }) 
    }
    handleClockOut = () => { API.post(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/clockOut`).then(this.props.refresh) }

    startStopBuild = (showDoc=true) => {
        const { worksOrder } = this.props;
        if (this.props.worksOrder.build?.active_time_sheet.length > 0 && _.find(worksOrder.build?.active_time_sheet, {wobt_staff_id: this.props.loggedInStaff.id})) 
            this.handleClockOut();
        else  
            this.handleClockIn(showDoc);
    }

    getStage = () => {
        API.get(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/${this.state.stage.wobs_id}`)
        .then(res => {this.setState({stage: res.data,});})
    }

    showProcessDocument = (url) => () => {
        const Element = <PDFViewer 
            pdfUrl={url}
            onClose={() => this.props.closeDialog()}
            downloadTitle={'process_document.pdf'}
            defaultFullScreen
        />

        this.props.deployDialog(Element, "", null, 'md');
    }

    handleWiteOff = () => {
        this.props.deployDialog(
            <WriteOff 
                stage={this.state.stage} 
                callBack={()=>{
                    if (this.props.worksOrder?.build?.active_time_sheet.length > 0 && _.find(this.state.worksOrder?.build?.active_time_sheet, {wobt_staff_id: this.props.loggedInStaff.id})) 
                        this.handleClockOut();
                    this.props.closeDialog();
                    this.props.refresh()
                    this.props.deployConfirmation('Do you want to replace the written off stock now?', 'Replace Written Off Stock', this.handlePickWiteOff, 'YesNo');
                }} 
                worksOrder={this.props.worksOrder} 
            />,
            'Write Off Parts',
            null,
            'md'
        )
    }

    handleGlobalWiteOff = () => {
        this.props.deployDialog(
            <GlobalWriteOff 
                stage={this.state.stage} 
                worksOrder={this.props.worksOrder}
                callBack={()=>{
                    if (this.props.worksOrder?.build?.active_time_sheet.length > 0 && _.find(this.state.worksOrder?.build?.active_time_sheet, {wobt_staff_id: this.props.loggedInStaff.id})) 
                        this.handleClockOut();
                    this.props.closeDialog();
                    this.props.refresh()
                    this.props.deployConfirmation('Do you want to replace the written off stock now?', 'Replace Written Off Stock', this.handlePickWiteOff, 'YesNo');
                }} 
            />,
            'Write Off Parts',
            null,
            'md'
        )
    }

    handlePickWiteOff = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/writeOffs/${this.props.worksOrder.active_write_off.wobwo_id}/start`)
        .then(res => {
            if (res.data.errors) {
                this.props.deploySnackBar('This write off is already being picked', 'error');
                this.props.refresh();
            } else {
                this.props.deployDialog(
                    <FullScreenDialog 
                        open={1} 
                        onClose={this.handlePickWiteOffCallBack} 
                        title="Pick Written Off Part" 
                        content={<WriteOffScanDialog worksOrder={this.props.worksOrder} callBack={this.handlePickWiteOffCallBack} />}
                    />, "", null, 'fs');
            }
        })
    }

    handlePickWiteOffCallBack = () => {
        API.post(`/worksOrders/${this.props.worksOrder.wo_id}/writeOffs/${this.props.worksOrder.active_write_off.wobwo_id}/end`)
        .then(res => {
            this.props.refresh();
            this.props.closeDialog();
        })
    }

    handleFirstOffPass = () => {
        API.get(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/${this.state.stage.wobs_id}`)
        .then(res => {
            if (!res.data.wobs_first_pass)
                this.props.deployDialog(
                    <FirstOffCheck 
                        stage={this.state.stage} 
                        callBack={()=>{
                            this.props.refresh();
                            this.props.closeDialog();
                        }} 
                        worksOrder={this.props.worksOrder} 
                    />,
                    'First Off Check',
                    null,
                    'md'
                );
                else {
                    this.props.deploySnackBar('First pass has already been submitted', 'error');
                    this.props.refresh() 
                };
        })
    }

        
    completeStage = () => {
        API.get(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/${this.state.stage.wobs_id}`)
        .then(res => {
            if (!res.data.wobs_complete)
                API.post(`/worksOrders/${this.props.worksOrder.wo_id}/build/${this.props.worksOrder.build.wob_id}/${this.state.stage.wobs_id}/complete`)
                .then(res => { this.props.refresh(); this.handleClockOut(); });
            else {
                this.props.deploySnackBar('This stage is already completed', 'error');
                this.handleClockOut();
                this.props.refresh() 
            };
        });
    }

    handleCompleteStage = () => {
        this.props.deployConfirmation(
            'Are you sure you want to complete this stage?, have you built all ordered units to the same standard as the first? Note: This will move you on to the next stage',
            'Complete Stage',
            this.completeStage
        );
    }

    handleStockAddition = () => {
        let { worksOrder } = this.props;
        let close = 0;
        if (worksOrder.build?.active_time_sheet.length === 0 || !_.find(worksOrder.build?.active_time_sheet, {wobt_staff_id: this.props.loggedInStaff.id})){
            this.startStopBuild(false);
            close = 1;
        }
        this.props.deployDialog(
            <FullScreenDialog 
                open={1} 
                onClose={() => {this.closeDialog(); close && this.startStopBuild(); }} 
                title="Scan Additional Parts" 
                content={<Addition stage={this.state.stage} callBack={() => {this.closeDialog(); close && this.startStopBuild(); }} worksOrder={this.props.worksOrder} />}
            />, "", null, 'fs')
    }

    closeDialog = () => {
        this.props.closeDialog();
        this.props.refresh();
    }

    handleStockReturn = () => {
        let { worksOrder } = this.props;
        let close = 0;
        if (worksOrder.build?.active_time_sheet.length === 0 || !_.find(worksOrder.build?.active_time_sheet, {wobt_staff_id: this.props.loggedInStaff.id})){
            this.startStopBuild(false);
            close = 1;
        }
        this.props.deployDialog(
            <FullScreenDialog 
                open={1} 
                onClose={() => {this.closeDialog(); close && this.startStopBuild();}} 
                title="Scan Return Parts" 
                content={<Return stage={this.state.stage} callBack={() => {this.closeDialog(); close && this.startStopBuild();}} worksOrder={this.props.worksOrder} />}
            />, "", null, 'fs')
    }

    handlePrintLabel = row => {
        API.get('/parts/' + row.part_id + '/stockLabel',{
            params: {
              type: 'partLabel'
            }
        })
        .then(result => {
            if(result.data) {
                this.props.printLabel(pdfDataFromBase64(result.data.pdf));
            }
        });
    }

    getColumns = () => {
        const {worksOrder} = this.props;
        let ret = [
            {
                heading:        '',
                field:          'default_image.thumbnail_file_path',
                fieldFormat:    'image',
                sizeToContent:  true,
                alignment:      'center',
            },
            {
                heading:       'Part Number',
                field:         'part_number',
                sizeToContent: true,
            },
            {
                heading: 'Part Description',
                field:   'part_description',
                truncate: true,
            },
            {   
                heading:       'Per Stage Build',
                field:         'saisp_qty',
                fieldFormat:   'realNumber:decimal:3',
                alignment:     'center',
                sizeToContent: true,
            },
            {
                heading:       'Stage Total',
                field:         'picked',
                fieldFormat:   'realNumber:decimal:3',
                alignment:     'center',
                sizeToContent: true,
            },
        ];

        worksOrder.additionals.length > 0 && ret.push({
            heading:       'Additional',
            field:         'additional',
            fieldFormat:   'realNumber:colorNumbers:decimal:3',
            alignment:     'center',
            sizeToContent: true,
        });
        worksOrder.returns.length > 0 && ret.push({
            heading:       'Returned',
            field:         'returns',
            alignment:     'center',
            fieldFormat:   'realNumber:decimal:3',
            sizeToContent: true,
            style:         i => ({color:parseFloat(i.returns) < 0 && colors.orange})
        })

        if (!!worksOrder.write_offs) 
            ret = [...ret,{
                heading:       'Written Off',
                field:         'written_off',
                fieldFormat:   'realNumber:decimal:3',
                alignment:     'center',
                sizeToContent: true,
                style:         i => ({color:parseFloat(i.written_off) > 0 && colors.red})
            }];

        (worksOrder.additionals.length > 0 || worksOrder.returns.length > 0 ) && ret.push({
            heading:       'Total',
            field:         'total',
            fieldFormat:   'realNumber:decimal:3',
            alignment:     'center',
            sizeToContent: true,
        })
        ret.push({
            heading:       '',
            field:         r => <AllIcon noMargin 
                                         icon={worksOrder.active_write_off?.wobwo_part_id == r.part_id ? icons.warning : (r.pickStatus === 'GlobalWriteOff' ? icons.parts : ( r.pickStatus === 'Additional' ? icons.plus : icons.picking ))} 
                                         color={worksOrder.active_write_off?.wobwo_part_id == r.part_id || r.pickStatus === 'GlobalWriteOff' ? colors.red : (  r.pickStatus === 'Not Required' ? colors.disabled : colors.green)} 
                                         tooltip={worksOrder.active_write_off?.wobwo_part_id == r.part_id ? 'Part has been written off' : (r.pickStatus === 'GlobalWriteOff' ? 'Part from another stage has been written off' : r.pickStatus)} />,
            alignment:     'center',
            sizeToContent: true,
        })

        ret.push({
            actions: i => ([
                {
                    name:    'Print Part Label',
                    icon:    icons.print,
                    onClick: this.handlePrintLabel,
                }
            ])
        })

        return ret;
    }

    render() {

        const { stage } = this.state;
        const { worksOrder } =  this.props;

        const complete = stage.wobs_complete;
        const active   = worksOrder.build.current_stage?.wobs_id == stage?.wobs_id;
        const inActive = !active;
        
        const activeBuild = worksOrder.build?.active_time_sheet.length > 0 && _.find(worksOrder.build?.active_time_sheet, {wobt_staff_id: this.props.loggedInStaff.id});

        const stageParts = _.filter(
            [..._.map(stage.instruction.parts, i => {
                let additionals = _.filter(worksOrder.additionals, j => j.wobas_part_id == i.part.part_id && j.wobas_wobs_id == stage.wobs_id); 
                let picked  = parseFloat(i.saisp_qty) * parseFloat(worksOrder.wo_qty);
                let additional = _.sumBy(additionals, j => parseFloat(j.wobas_qty));
                let returns = 0 - _.sumBy(_.filter(worksOrder.returns, {wobsr_part_id: i.part.part_id,wobsr_wobs_id: stage.wobs_id}), j => parseFloat(j.wobsr_qty));
                let written_off = _.sumBy(
                    _.filter(worksOrder.write_offs, {wobwo_part_id: i.part.part_id, wobwo_wobs_id: stage.wobs_id}),
                    i => parseFloat(i.wobwo_picked)
                ) || 0;
                let pickStatus  = _.find(worksOrder.pick.details, {wopd_part_id: i.part.part_id})?.wopd_status;
                let shortStatus = _.find(worksOrder?.shortage?.details, {wossd_part_id: i.part.part_id})?.wossd_status;
                if (shortStatus) pickStatus = shortStatus;

                if (pickStatus === 'Not Required') picked = 0;

                return _.assign({
                    ...i, 
                    ...i.part,
                    picked,
                    additional,
                    returns,
                    written_off,
                    original:   1,
                    total:      picked + additional + returns + written_off,
                    pickStatus,
                })
            }),..._.map(_.uniqBy(_.filter(worksOrder.additionals, i => !_.find(stage.instruction.parts, {saisp_part_id: i.wobas_part_id}) && i.wobas_wobs_id === stage.wobs_id), 'wobas_part_id' ), i => {
                let additional = _.sumBy(_.filter(worksOrder.additionals, {wobas_part_id: i.wobas_part_id, wobas_wobs_id: stage.wobs_id}), j => parseFloat(j.wobas_qty));
                let returns = 0 - _.sumBy(_.filter(worksOrder.returns, {wobsr_part_id: i.part.part_id, wobsr_wobs_id: stage.wobs_id}), j => parseFloat(j.wobsr_qty));
                let written_off = _.sumBy(
                    _.filter(worksOrder.write_offs, {wobwo_part_id: i.part.part_id, wobwo_wobs_id: stage.wobs_id}),
                    i => parseFloat(i.wobwo_picked)
                ) || 0;  
                return _.assign({
                    ...i, 
                    ...i.part,
                    additional,
                    returns,
                    written_off,
                    original: 0,
                    total:    additional + returns + written_off,
                    pickStatus: 'Additional'
                })
            })]
        , i => i.total !== 0 || i.original )

        return (
            <Grid item xs={12} lg={12}>
                <ExpansionPanel expanded={ complete ? null : active}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{backgroundColor: complete ? colors.green : ( active ? colors.orange : colors.disabled )}}>
                        <Typography>Stage {stage.instruction.sais_number} - {stage.instruction.sais_name}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={3}>
                            {stageParts.length > 0 ? [
                                <Grid item xs={12} lg={12}>
                                    <Typography variant='h5' style={{marginBottom: 10}}>Parts</Typography>
                                    <DataTable
                                        config={{
                                            key:   'saisp_id',
                                            style: rowData => ({
                                                color:      worksOrder.active_write_off?.wobwo_part_id == rowData.part_id && colors.red,
                                                fontWeight: worksOrder.active_write_off?.wobwo_part_id == rowData.part_id ? 'bold' : 'normal',
                                            })
                                        }}
                                        columns={ this.getColumns() }
                                        rows={_.orderBy(
                                           [..._.filter(stageParts, i => i.pickStatus !== 'Not Required' || parseFloat(parseFloat(i.additional) - parseFloat(i.returns)) !== 0),
                                           ..._.map( _.filter(worksOrder.write_offs, i => {
                                            return (i.wobwo_wobs_id === stage.wobs_id) && !_.find(stageParts, {part_id: i.wobwo_part_id});
                                           }), i => {
                                            return {
                                                ...i, 
                                                ...i.part,
                                                picked: 0,
                                                additional: 0,
                                                returns : 0,
                                                written_off: i.wobwo_qty,
                                                original:   1,
                                                total:      i.wobwo_qty,
                                                pickStatus: 'GlobalWriteOff'
                                            }})]
                                        , i => i.part_number, 'asc')}
                                    />
                                </Grid>
                            ] : ( _.filter(worksOrder.write_offs, {wobwo_wobs_id: stage.wobs_id}).length > 0 &&
                                <Grid item xs={12} lg={12}>
                                    <Typography variant='h5' style={{marginBottom: 10}}>Parts</Typography>
                                    <DataTable
                                        config={{
                                            key:   'saisp_id',
                                            style: rowData => ({
                                                color:      worksOrder.active_write_off?.wobwo_part_id == rowData.part_id && colors.red,
                                                fontWeight: worksOrder.active_write_off?.wobwo_part_id == rowData.part_id ? 'bold' : 'normal',
                                            })
                                        }}
                                        columns={ this.getColumns() }
                                        rows={_.orderBy(_.map( _.filter(worksOrder.write_offs, {wobwo_wobs_id: stage.wobs_id}), i => {
                                            return {
                                                ...i, 
                                                ...i.part,
                                                picked: 0,
                                                additional: 0,
                                                returns : 0,
                                                written_off: i.wobwo_qty,
                                                original:   1,
                                                total:      i.wobwo_qty,
                                                pickStatus: 'GlobalWriteOff'
                                            }}), i => i.part_number, 'asc')}
                                    />
                                </Grid>
                            )} 
                            {stage.instruction.jigs.length > 0 && [
                                <Grid item xs={12} lg={12}>
                                    <Typography variant='h5' style={{marginBottom: 10}}>Jigs</Typography>
                                    <DataTable
                                        config={{
                                            key:      'jig.part_id',
                                            noHeader: true,
                                        }}
                                        columns={[
                                            {
                                                heading:       '',
                                                field:         'jig.default_image.thumbnail_file_path',
                                                fieldFormat:   'image',
                                                sizeToContent: true,
                                            },
                                            {
                                                heading:       'Part',
                                                field:         'jig.part_number',
                                                sizeToContent: true,
                                            },
                                            {
                                                heading: 'Description',
                                                field:   'jig.part_description',
                                            },
                                        ]}
                                        rows={stage.instruction.jigs}
                                    />
                                </Grid>
                            ]} 
                            <Grid item xs={12}></Grid>
                            {!this.props.complete && <>
                                {complete ? 
                                    <>
                                        {stageParts.length > 0 && [
                                            <Grid item xs={6}>
                                                <Button variant='outlined' fullWidth onClick={this.handleStockAddition}><AllIcon icon={icons.plus}/>Stock Addition</Button>
                                            </Grid>,
                                            <Grid item xs={6}>
                                                <Button variant='outlined' fullWidth onClick={this.handleStockReturn}><AllIcon icon={icons.minus}/>Stock Return</Button>
                                            </Grid>,
                                        ] }
                                    </>:<>
                                        { worksOrder.active_write_off ? 
                                            <>
                                                <Grid item xs={12} lg={12}>
                                                    <Button variant='contained' color='secondary' fullWidth onClick={this.handlePickWiteOff}>Pick Written Off Stock</Button>
                                                </Grid>
                                                {stageParts.length > 0 && [
                                                    <Grid item xs={6} lg={6}>
                                                        <Button variant='outlined' fullWidth onClick={this.handleStockAddition}><AllIcon icon={icons.plus}/>Stock Addition</Button>
                                                    </Grid>,
                                                    <Grid item xs={6} lg={6}>
                                                        <Button variant='outlined' fullWidth onClick={this.handleStockReturn}><AllIcon icon={icons.minus}/>Stock Return</Button>
                                                    </Grid>
                                                ]}
                                            </> : 
                                            <>
                                                <Grid item xs={ stageParts.length > 0 ? 6 : 12} lg={stageParts.length > 0 ? ( complete ? 4 : 3 ) : 12}>
                                                    <Button variant='contained' color={activeBuild ?'secondary':'primary'} fullWidth onClick={this.startStopBuild}>{activeBuild ?'Stop':'Start'} Build</Button>
                                                </Grid>
                                                {stageParts.length > 0 ? [
                                                    !complete &&
                                                        <Grid item xs={6} lg={3}>
                                                            <Button variant='outlined' fullWidth 
                                                                onClick={!!stage.instruction.sais_global_write_off ? this.handleGlobalWiteOff : this.handleWiteOff }
                                                                disabled={!stage.instruction.sais_global_write_off && _.filter(stageParts, r => r.pickStatus !== 'Not Required').length == 0}
                                                            ><AllIcon icon={icons.broken} color={(!stage.instruction.sais_global_write_off && _.filter(stageParts, r => r.pickStatus !== 'Not Required').length == 0 ) && colors.disabled}/>Write Off</Button>
                                                        </Grid>,
                                                    <Grid item xs={6} lg={( complete ? 4 : 3 )}>
                                                        <Button variant='outlined' fullWidth onClick={this.handleStockAddition}><AllIcon icon={icons.plus}/>Stock Addition</Button>
                                                    </Grid>,
                                                    <Grid item xs={6} lg={( complete ? 4 : 3 )}>
                                                        <Button variant='outlined' fullWidth onClick={this.handleStockReturn}><AllIcon icon={icons.minus}/>Stock Return</Button>
                                                    </Grid>
                                                ] : 
                                                    (!!stage.instruction.sais_global_write_off &&
                                                        <Grid item xs={12} lg={12}>
                                                             <Button variant='outlined' fullWidth 
                                                                onClick={this.handleGlobalWiteOff}
                                                            ><AllIcon icon={icons.broken}/>Write Off</Button>
                                                        </Grid>
                                                    )
                                                }
                                            </>
                                            
                                        }
                                        
                                        {activeBuild && !worksOrder.active_write_off && 
                                            <>
                                                {(worksOrder.wo_version && (worksOrder.wo_version !== worksOrder.part.part_issue_status_pdf)) ?
                                                    <>
                                                        <Grid item xs={(worksOrder.wo_version && (worksOrder.wo_version !== worksOrder.part.part_issue_status_pdf)) ? 12 : 12}>
                                                            <Button fullWidth variant='outlined' style={{background: colors.orange, color: colors.white}} onClick={this.showProcessDocument(this.props.worksOrder.part.pdf_drawing.url)}><AllIcon icon={icons.proccessDocument} color={colors.white} />Process Document v{worksOrder.wo_version} (Created)</Button>
                                                        </Grid>
                                                        {!!(worksOrder.wo_version && (worksOrder.wo_version !== worksOrder.part.part_issue_status_pdf)) &&
                                                            <Grid item xs={12}>
                                                                <Button fullWidth variant='outlined' onClick={this.showProcessDocument(this.props.worksOrder.part.pdf_drawing_current.url)}><AllIcon icon={icons.proccessDocument} />Process Document v{worksOrder.part.part_issue_status_pdf} (Current)</Button>
                                                            </Grid>
                                                        }
                                                    </> : 
                                                    <Grid item xs={12}>
                                                        <Button fullWidth variant='outlined'  style={{background: colors.orange, color: colors.white}} onClick={this.showProcessDocument(this.props.worksOrder.part.pdf_drawing_current.url)}><AllIcon white icon={icons.proccessDocument} />Process Document v{worksOrder.part.part_issue_status_pdf}</Button>
                                                    </Grid>
                                                }
                                                <Grid item xs={6}>
                                                    <Button fullWidth variant='contained' color='secondary' style={{background: stage.wobs_first_pass ? colors.green : colors.orange}} onClick={!stage.wobs_first_pass ? this.handleFirstOffPass : () => {}} >First Off Check</Button> 
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button fullWidth variant='contained' style={{color: colors.white, background: stage.wobs_first_pass ? colors.orange : colors.red }} onClick={this.handleCompleteStage} disabled={!stage.wobs_first_pass}>Complete Stage</Button>
                                                </Grid>
                                            </>
                                        }
                                    </>
                                }
                            </>}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        )
    }
}

function mapStateToProps(state){
    return {
		loggedInStaff: state.staffAuth.staff
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deploySnackBar:     ( msg,variant )                         => dispatch( deploySnackBar(variant, msg) ),
        deployDialog:       (content, title, variant, size)         => dispatch( deployDialog(content, title, variant, size) ),
        closeDialog:        ()                                      => dispatch( closeDialog() ),
        deployConfirmation: (message, title, success, variant=null) => dispatch(deployConfirmation(message, title, success, null, variant)),
        printLabel:         (data)                                  => dispatch(printLabel(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)
