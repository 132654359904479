import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import MuiDialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import PaperComponent from './PaperComponent';

import {colors} from 'Helpers/ColourHelper';

const useStyles = makeStyles(theme => ({
  heading: {
    textTransform: 'capitalize'
  },
  content : {
    paddingBottom: theme.spacing(2)
  },
  dialogTitleSuccess: {
    backgroundColor: colors.green,
    color: 'white',
    fontWeight: 'bold'
  },
  dialogTitleWarning: {
    backgroundColor: colors.red,
    color: 'white',
    fontWeight: 'bold'
  },
  dialogTitleAmber: {
    backgroundColor: colors.yellow,
    color: 'white',
    fontWeight: 'bold'
  },
  dialogTitleSystem: {
    backgroundColor: colors.blue,
    color: 'white',
    fontWeight: 'bold'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({dialogOpen, dialogContent, dialogTitle, dialogVariant, dialogSize, handleDialogClose, dialogStyle}) => {
    const classes = useStyles();

    const generateDialogTitleClassName = () => {
      let className = '';
      
      switch(dialogVariant) {
        case 'success':
          className = classes.dialogTitleSuccess;
        break;
        case 'warning':
          className = classes.dialogTitleWarning;
        break;
        case 'amber':
          className = classes.dialogTitleAmber;
        break;
        case 'system':
          className = classes.dialogTitleSystem;
        break;
        default:
        break;
      }

      return className;
    }

    return (
      <MuiDialog
        disableEscapeKeyDown
        open={dialogOpen || false}
        TransitionComponent={Transition}
        onClose={handleDialogClose}
        fullScreen={dialogSize === 'fullScreen' || dialogSize === 'fs'}
        maxWidth={dialogSize === 'fullScreen' || dialogSize === 'fs' ? null : (dialogSize || 'md')}
        scroll="body"
        fullWidth
        disableBackdropClick
        PaperProps={{style:dialogStyle}}
        PaperComponent={PaperComponent}
      >
        {(dialogTitle && (
          <React.Fragment>
            <DialogTitle className={`${classes.heading} ${generateDialogTitleClassName()}`}>
              {dialogTitle}
            </DialogTitle>
            <DialogContent className={classes.content}>
              {dialogContent}
            </DialogContent>
          </React.Fragment>
        )) || (
            dialogContent
        )}
      </MuiDialog>
    );
}

export default Dialog;